@charset "UTF-8";
/* FONTS
--------------------------------------------------------------------------------------------*/
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700|Catamaran:200,300,400,600);
/* VARIABLES
--------------------------------------------------------------------------------------------*/
/*
    BRAND COLORS
*/
/*
    DEFAULTS
*/
/*
    OTHER VARIABLES
*/
/*
    LIST FOR DEFAULT BG COLOR GENERATION
*/
/*
    LIST FOR DEFAULT FONT COLOR GENERATION
*/
/*
    LIST FOR DEFAULT FILL COLOR GENERATION
*/
/* FUNCTIONS
--------------------------------------------------------------------------------------------*/
/* MIXINS
--------------------------------------------------------------------------------------------*/
/* MIXIN - BOTTOM ITEMS NO BORDER 
----------------------------------------------------------------------------------------------------

    Removes border bottom from last items in a grid

*/
/* MIXIN - BORDER RESPONSIVENESS
----------------------------------------------------------------------------------------------------

    This mixin calculates the tranparent borders on grids, so you have borders between items, but not on the left side. 
    It accepts a pattern, (S1-MP2), which means on small 1 item per row, on medium-portrait 2, etc. 
    The first item can be set as large and therefore 100% width of parent container size.

*/
/*------------------------
mixin that calculates if text needs to be light or dark
depending on the background color passed.

From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
			
Color brightness is determined by the following formula: 
((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
------------------------*/
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium-portrait=40em&medium=50em&large=64.0625em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 95%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #353535;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.8;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: 700;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Catamaran", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #353535;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.57895rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.31579rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.25rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.18421rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.11842rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1.05263rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 50em) {
  h1 {
    font-size: 2.36842rem; }
  h2 {
    font-size: 2.10526rem; }
  h3 {
    font-size: 1.84211rem; }
  h4 {
    font-size: 1.57895rem; }
  h5 {
    font-size: 1.31579rem; }
  h6 {
    font-size: 1.05263rem; } }

a {
  line-height: inherit;
  color: #353535;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #2e2e2e; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 78.94737rem;
  height: 0;
  margin: 1.31579rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.8; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: 700; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.59211rem 1.31579rem 0 1.25rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.8;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.85526rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #1D1D1B;
  color: #353535;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.13158rem 0.32895rem 0.06579rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 400;
  color: #1D1D1B; }

kbd {
  margin: 0;
  padding: 0.13158rem 0.26316rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #1D1D1B; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 118.75%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-portrait-text-left {
    text-align: left; }
  .medium-portrait-text-right {
    text-align: right; }
  .medium-portrait-text-center {
    text-align: center; }
  .medium-portrait-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 50em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64.0625em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #353535;
  color: #ffffff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #2d2d2d;
    color: #ffffff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #353535;
    color: #ffffff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #2b2b2b;
      color: #ffffff; }
  .button.secondary {
    background-color: #777;
    color: #ffffff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5f5f5f;
      color: #ffffff; }
  .button.success {
    background-color: #3adb76;
    color: #1D1D1B; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #1D1D1B; }
  .button.warning {
    background-color: #ffae00;
    color: #1D1D1B; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #1D1D1B; }
  .button.alert {
    background-color: #ec5840;
    color: #1D1D1B; }
    .button.alert:hover, .button.alert:focus {
      background-color: #da3116;
      color: #1D1D1B; }
  .button.hollow {
    border: 1px solid #353535;
    color: #353535; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #1b1b1b;
      color: #1b1b1b; }
    .button.hollow.primary {
      border: 1px solid #353535;
      color: #353535; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #1b1b1b;
        color: #1b1b1b; }
    .button.hollow.secondary {
      border: 1px solid #777;
      color: #777; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3c3c3c;
        color: #3c3c3c; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #ec5840;
      color: #ec5840; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #881f0e;
        color: #881f0e; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #353535;
      color: #ffffff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #353535;
        color: #ffffff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #777;
        color: #ffffff; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #1D1D1B; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #1D1D1B; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #ec5840;
        color: #1D1D1B; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ffffff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #353535; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #353535; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #777; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #ec5840; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.56579rem;
  margin: 0 0 1.05263rem;
  padding: 0.52632rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: inset 0 1px 2px rgba(29, 29, 27, 0.1);
  font-family: inherit;
  font-size: 1.05263rem;
  font-weight: 400;
  color: #1D1D1B;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #ffffff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: default; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1.05263rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.52632rem;
  margin-right: 1.05263rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.52632rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.92105rem;
  font-weight: 400;
  line-height: 1.8;
  color: #1D1D1B; }
  label.middle {
    margin: 0 0 1.05263rem;
    padding: 0.59211rem 0; }

.help-text {
  margin-top: -0.52632rem;
  font-size: 0.85526rem;
  font-style: italic;
  color: #1D1D1B; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1.05263rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #1D1D1B;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.63158rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.05263rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.52632rem; }

.fieldset {
  margin: 1.18421rem 0;
  padding: 1.31579rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.19737rem;
    padding: 0 0.19737rem;
    background: #ffffff; }

select {
  height: 2.56579rem;
  margin: 0 0 1.05263rem;
  padding: 0.52632rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1.05263rem;
  line-height: normal;
  color: #1D1D1B;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1.05263rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.57895rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #ffffff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: default; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #ec5840;
  background-color: #fdeeec; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #ec5840; }

.is-invalid-label {
  color: #ec5840; }

.form-error {
  display: none;
  margin-top: -0.52632rem;
  margin-bottom: 1.05263rem;
  font-size: 0.78947rem;
  font-weight: 700;
  color: #ec5840; }
  .form-error.is-visible {
    display: block; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #353535 transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.72368rem;
    color: #1D1D1B;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #353535; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #f0f0f0;
  border-radius: 0;
  background-color: #ffffff;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #353535 transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #353535 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #353535; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-portrait-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-portrait-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-portrait-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-portrait-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #353535 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-portrait-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-portrait-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-portrait-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-portrait-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-portrait-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #353535 transparent transparent; }
  .dropdown.menu.medium-portrait-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #353535; } }

@media print, screen and (min-width: 50em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #353535 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #353535 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #353535; } }

@media print, screen and (min-width: 64.0625em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #353535 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #353535 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #353535; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #ffffff; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #353535 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #353535; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1.05263rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium-portrait {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium-portrait {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 49.9375em) {
  .hide-for-medium-portrait-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 50em) {
  .show-for-medium-portrait-only {
    display: none !important; } }

@media print, screen and (min-width: 50em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 49.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 50em) and (max-width: 64em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 49.9375em), screen and (min-width: 64.0625em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64.0625em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 64em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64.0625em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 64em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

/* SLICK */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* PHOTOSWIPE */
/* STUCTURE
--------------------------------------------------------------------------------------------*/
figure {
  margin: 0; }

[data-anime-type="none"] [data-anime-elem] {
  opacity: 1; }

/* GENERICS
--------------------------------------------------------------------------------------------*/
/* GENERICS - ANCHORS
--------------------------------------------------------------------------------------------*/
@media print, screen and (min-width: 64.0625em) {
  a[href^="tel"] {
    pointer-events: none; } }

/* GENERICS - BUTTON GROUP
--------------------------------------------------------------------------------------------*/
button:active, .button:active {
  transform: translateY(1px); }

button:focus, .button:focus {
  outline: none; }

.button {
  text-transform: uppercase;
  font-weight: 700;
  padding: 1.2em;
  text-align: left;
  border-radius: 6px;
  border: solid 3px;
  border-color: #ffffff;
  position: relative;
  transition: opacity .3s ease;
  will-change: opacity;
  display: inline-flex;
  align-items: center;
  background: #F28C00;
  background: linear-gradient(-90deg, #a56000fa, #F28C00);
  color: #ffffff; }
  .button .svg-container {
    display: none;
    width: 2em;
    height: 2em; }
  .button svg {
    width: 2em;
    height: 2em;
    fill: currentColor; }
  .button.v_expanded {
    display: block;
    width: 100%; }
  .button.v_brand_primary {
    background: #F28C00;
    color: #353535; }
  .button.v_brand_primary-darker {
    background: #a56000fa;
    color: #ffffff; }
  .button.v_brand_secondary {
    background: #1d1d1b;
    color: #ffffff; }
  .button.v_brand_secondary-darker {
    background: #10100f;
    color: #ffffff; }
  .button.v_brand_tertiary {
    background: #424242;
    color: #ffffff; }
  .button.v_brand_tertiary-darker {
    background: #353535;
    color: #ffffff; }
  .button.v_brand_black {
    background: #1D1D1B;
    color: #ffffff; }
  .button.v_brand_white {
    background: #ffffff;
    color: #353535; }
  .button.v_brand_light-gray {
    background: #f0f0f0;
    color: #353535; }
  .button.v_brand_lightest-gray {
    background: #fafafd;
    color: #353535; }
  .button.v_ghost {
    background: none; }
  .button.v_ghost_brand_primary {
    background: none;
    color: #F28C00;
    border-color: #F28C00; }
  .button.v_ghost_brand_primary-darker {
    background: none;
    color: #a56000fa;
    border-color: #a56000fa; }
  .button.v_ghost_brand_secondary {
    background: none;
    color: #1d1d1b;
    border-color: #1d1d1b; }
  .button.v_ghost_brand_secondary-darker {
    background: none;
    color: #10100f;
    border-color: #10100f; }
  .button.v_ghost_brand_tertiary {
    background: none;
    color: #424242;
    border-color: #424242; }
  .button.v_ghost_brand_tertiary-darker {
    background: none;
    color: #353535;
    border-color: #353535; }
  .button.v_ghost_brand_black {
    background: none;
    color: #1D1D1B;
    border-color: #1D1D1B; }
  .button.v_ghost_brand_white {
    background: none;
    color: #ffffff;
    border-color: #ffffff; }
  .button.v_ghost_brand_light-gray {
    background: none;
    color: #f0f0f0;
    border-color: #f0f0f0; }
  .button.v_ghost_brand_lightest-gray {
    background: none;
    color: #fafafd;
    border-color: #fafafd; }
  .button.v_transparent {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    background: none;
    border: none;
    color: #353535; }
    .button.v_transparent:hover {
      color: #353535;
      opacity: 1; }
  .button.v_transparent_body {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    background: none;
    border: none;
    text-transform: none;
    color: #353535;
    font-family: Roboto, Arial, sans-serif;
    font-weight: 400;
    font-style: normal; }
    .button.v_transparent_body .svg-container {
      width: 1.4em;
      height: 1.4em; }
    .button.v_transparent_body svg {
      width: 1.4em;
      height: 1.4em; }
    .button.v_transparent_body:hover {
      color: #353535;
      opacity: 1; }
  .button.v_has-icon-left {
    flex-direction: row-reverse;
    padding: 0.84em; }
    .button.v_has-icon-left .svg-container {
      display: inline-block;
      margin-right: 1em; }
  .button.v_has-icon-right {
    padding: 0.84em; }
    .button.v_has-icon-right .svg-container {
      display: inline-block;
      margin-left: 1em; }
  .button:hover {
    opacity: .8; }

/* GENERICS - DROPDOWN
--------------------------------------------------------------------------------------------*/
.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  border: inset 3px;
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1d1d1b transparent transparent;
  right: 7px;
  margin-top: -3px; }

.is-dropdown-submenu {
  border: none;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 2px 2px 10px rgba(0, 0, 0, 0.1); }

/* GENERICS - FORM
--------------------------------------------------------------------------------------------*/
input,
textarea,
select {
  border-color: rgba(66, 66, 66, 0.3) !important;
  border-radius: 3px !important;
  padding: 0rem 2rem .2rem 1rem !important;
  height: 3rem !important; }
  input:disabled,
  textarea:disabled,
  select:disabled {
    background-color: transparent;
    border-color: rgba(66, 66, 66, 0.15) !important;
    color: #424242 !important;
    opacity: 1 !important; }
  input::placeholder,
  textarea::placeholder,
  select::placeholder {
    font-style: italic;
    color: rgba(66, 66, 66, 0.6) !important; }
  input:focus,
  textarea:focus,
  select:focus {
    border-color: #424242 !important; }

select {
  padding: 0rem 2.5rem 0 1rem !important;
  background-position: right -18px center; }

textarea {
  min-height: 100px; }

input[type="submit"],
input[type="button"] {
  padding: 1rem 1rem 1rem 1rem !important;
  border: solid 3px #f0f0f0 !important; }

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0; }

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  font-size: 0.9rem; }

/* GENERICS - HR
--------------------------------------------------------------------------------------------*/
hr {
  border-bottom: solid 1px rgba(29, 29, 27, 0.15);
  margin: 30px 0;
  max-width: 100%; }

/* LIST SEARCH
--------------------------------------------------------------------------------------------*/
.ac_list-search {
  list-style: none;
  margin: 0;
  border-top: solid 1px rgba(102, 102, 102, 0.2); }

.ac_list-search_item {
  border-bottom: solid 1px rgba(102, 102, 102, 0.2); }

.ac_list-search_item_link {
  padding: 2em 0;
  display: block;
  text-decoration: none !important; }

/* GENERICS - MAPS
--------------------------------------------------------------------------------------------*/
.gm-style {
  font-family: Roboto, Arial, sans-serif !important; }

/* GENERICS - SLICK SLIDER
--------------------------------------------------------------------------------------------*/
.slick-dots {
  position: absolute;
  z-index: 222;
  left: 50%;
  bottom: -2em;
  list-style: none;
  display: flex;
  margin: 0;
  transform: translateX(-50%); }
  @media print, screen and (min-width: 50em) {
    .slick-dots {
      bottom: -4em; } }
  .slick-dots button {
    border: solid 2px rgba(53, 53, 53, 0.1);
    width: 1em;
    height: 1em;
    border-radius: 100%;
    margin: 0 .15em;
    text-indent: -99999px; }
    .slick-dots button:hover {
      cursor: pointer; }
    .slick-dots button:focus {
      outline: none; }
  .slick-dots .slick-active button {
    background: #353535; }

.slick-slide:focus {
  outline: none; }

button.slick-prev,
button.slick-next {
  position: absolute;
  z-index: 222;
  top: 50%;
  transform: translateY(-50%);
  width: 3em;
  height: 3em;
  padding: .5em;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); }
  button.slick-prev svg,
  button.slick-next svg {
    width: 2em;
    height: 2em; }
  button.slick-prev:hover,
  button.slick-next:hover {
    cursor: pointer; }
  button.slick-prev:focus,
  button.slick-next:focus {
    outline: none; }

button.slick-prev {
  left: 0; }

button.slick-next {
  right: 0; }

/* GENERICS - SVG
--------------------------------------------------------------------------------------------*/
/* PARTS
--------------------------------------------------------------------------------------------*/
/* PART - CONTENT
--------------------------------------------------------------------------------------------*/
.ac_content {
  position: relative; }

.ac_content_container {
  position: relative;
  z-index: 1;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px; }
  .ac_content_container.a_collapsed {
    padding: 0; }

.ac_content_flex {
  padding: 0 10px; }
  @media print, screen and (min-width: 50em) {
    .ac_content_flex {
      display: flex;
      padding: 0 10px; } }

.ac_content_full,
.ac_content_fullwidth {
  padding: 0 10px;
  width: 100%; }

.ac_content_half,
.ac_content_1-2 {
  padding: 0 10px;
  width: 100%; }
  @media print, screen and (min-width: 50em) {
    .ac_content_half,
    .ac_content_1-2 {
      flex: 0 1 50%;
      max-width: 50%; } }

.ac_content_1-3 {
  padding: 0 10px;
  width: 100%; }
  @media print, screen and (min-width: 50em) {
    .ac_content_1-3 {
      flex: 0 1 33.33333%;
      max-width: 33.33333%; } }

.ac_content_2-3 {
  padding: 0 10px;
  width: 100%; }
  @media print, screen and (min-width: 50em) {
    .ac_content_2-3 {
      flex: 0 1 66.66667%;
      max-width: 66.66667%; } }

.ac_content_1-4 {
  padding: 0 10px;
  width: 100%; }
  @media print, screen and (min-width: 50em) {
    .ac_content_1-4 {
      flex: 0 1 25%;
      max-width: 25%; } }

.ac_content_3-4 {
  padding: 0 10px;
  width: 100%; }
  @media print, screen and (min-width: 50em) {
    .ac_content_3-4 {
      flex: 0 1 75%;
      max-width: 75%; } }

.ac_content_1-5 {
  padding: 0 10px;
  width: 100%; }
  @media print, screen and (min-width: 50em) {
    .ac_content_1-5 {
      flex: 0 1 20%;
      max-width: 40%; } }

.ac_content_2-5 {
  padding: 0 10px;
  width: 100%; }
  @media print, screen and (min-width: 50em) {
    .ac_content_2-5 {
      flex: 0 1 40%;
      max-width: 40%; } }

.ac_content_3-5 {
  padding: 0 10px;
  width: 100%; }
  @media print, screen and (min-width: 50em) {
    .ac_content_3-5 {
      flex: 0 1 60%;
      max-width: 60%; } }

.ac_content_4-5 {
  padding: 0 10px;
  width: 100%; }
  @media print, screen and (min-width: 50em) {
    .ac_content_4-5 {
      flex: 0 1 80%;
      max-width: 80%; } }

/* GRID - BASED ON DATA ATTRIBUTES
--------------------------------------------------------------------------------------------*/
[data-s-amount="1"] [data-s-amount-item] {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  [data-s-amount="2"] [data-s-amount-item] {
    width: 50%;
    max-width: 50%; } }

@media print, screen and (min-width: 40em) {
  [data-s-amount="3"] [data-s-amount-item] {
    width: 50%;
    max-width: 50%; } }

@media print, screen and (min-width: 50em) {
  [data-s-amount="3"] [data-s-amount-item] {
    width: 33.33333%;
    max-width: 33.33333%; } }

@media print, screen and (min-width: 40em) {
  [data-s-amount="4"] [data-s-amount-item] {
    width: 50%;
    max-width: 50%; } }

@media print, screen and (min-width: 64.0625em) {
  [data-s-amount="4"] [data-s-amount-item] {
    width: 25%;
    max-width: 25%; } }

@media print, screen and (min-width: 40em) {
  [data-s-amount="5"] [data-s-amount-item] {
    width: 50%;
    max-width: 50%; } }

@media print, screen and (min-width: 64.0625em) {
  [data-s-amount="5"] [data-s-amount-item] {
    width: 20%;
    max-width: 20%; } }

/* BACKDROP 
--------------------------------------------------------------------------------------------*/
.ac_backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden; }

.ac_backdrop_image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.ac_backdrop_image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  opacity: 1; }

.ac_backdrop_video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2; }
  .ac_backdrop_video-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%); }

/* SECTION - DIVIDER 
--------------------------------------------------------------------------------------------*/
.ac_section_divider {
  pointer-events: none;
  /* SECTION - DIVIDER - ANGLED SVG
--------------------------------------------------------------------------------------------*/
  /* SECTION - DIVIDER - ANGLED SVG
--------------------------------------------------------------------------------------------*/
  /* SECTION - DIVIDER - ANGLED SVG
--------------------------------------------------------------------------------------------*/
  /* SECTION - DIVIDER - DOTTED
--------------------------------------------------------------------------------------------*/
  /* SECTION - DIVIDER - DOTTED
--------------------------------------------------------------------------------------------*/
  /* SECTION - DIVIDER - ANGLED SVG
--------------------------------------------------------------------------------------------*/
  /* SECTION - DIVIDER - SPIKED
--------------------------------------------------------------------------------------------*/
  /* SECTION - DIVIDER - SPIKED - SMALL
--------------------------------------------------------------------------------------------*/ }
  .ac_section_divider[data-s-divider-type="angled-svg"] .ac_section_divider_top {
    top: -3vw;
    position: absolute;
    z-index: 11;
    width: 100%; }
    .ac_section_divider[data-s-divider-type="angled-svg"] .ac_section_divider_top svg {
      position: absolute;
      width: 100%;
      height: 3vw;
      transform: translateY(1px); }
  .ac_section_divider[data-s-divider-type="angled-svg"] .ac_section_divider_bottom {
    bottom: -3vw;
    position: absolute;
    z-index: 11;
    width: 100%;
    transform: rotate(180deg); }
    .ac_section_divider[data-s-divider-type="angled-svg"] .ac_section_divider_bottom svg {
      position: absolute;
      width: 100%;
      height: 3vw;
      transform: translateY(1px); }
  .ac_section_divider[data-s-divider-type="butt-cheeks"] .ac_section_divider_top {
    top: -50px;
    position: absolute;
    z-index: 11;
    width: 100%;
    display: flex; }
  .ac_section_divider[data-s-divider-type="butt-cheeks"] .ac_section_divider_bottom {
    bottom: -50px;
    position: absolute;
    z-index: 11;
    width: 100%;
    display: flex;
    transform: rotate(180deg); }
  .ac_section_divider[data-s-divider-type="butt-cheeks"] .ac_section_divider_cheek_left {
    width: 50%;
    height: 50px;
    border-top-right-radius: 50px; }
  .ac_section_divider[data-s-divider-type="butt-cheeks"] .ac_section_divider_cheek_right {
    width: 50%;
    height: 50px;
    border-top-left-radius: 50px; }
  .ac_section_divider[data-s-divider-type="curved-cheeks"] .ac_section_divider_top {
    top: -10vw;
    position: absolute;
    z-index: 11;
    width: 100%; }
    .ac_section_divider[data-s-divider-type="curved-cheeks"] .ac_section_divider_top svg {
      position: absolute;
      width: 100%;
      height: 10vw;
      transform: translateY(1px); }
  .ac_section_divider[data-s-divider-type="curved-cheeks"] .ac_section_divider_bottom {
    bottom: -10vw;
    position: absolute;
    z-index: 11;
    width: 100%;
    transform: rotate(180deg); }
    .ac_section_divider[data-s-divider-type="curved-cheeks"] .ac_section_divider_bottom svg {
      position: absolute;
      width: 100%;
      height: 10vw;
      transform: translateY(1px); }
  .ac_section_divider[data-s-divider-type="dotted"] .ac_section_divider_top {
    top: -19px;
    position: absolute;
    z-index: 11;
    width: 100%; }
  .ac_section_divider[data-s-divider-type="dotted"] .ac_section_divider_bottom {
    position: absolute;
    z-index: 11;
    width: 100%;
    bottom: -19.53px; }
    .ac_section_divider[data-s-divider-type="dotted"] .ac_section_divider_bottom svg {
      transform: rotate(180deg); }
  .ac_section_divider[data-s-divider-type="dotted"] svg {
    height: 20px;
    width: 100%; }
  .ac_section_divider[data-s-divider-type="semi-transparent"] .ac_section_divider_top {
    position: absolute;
    z-index: 11;
    width: 100%; }
    .ac_section_divider[data-s-divider-type="semi-transparent"] .ac_section_divider_top svg {
      position: absolute;
      width: 100%; }
    .ac_section_divider[data-s-divider-type="semi-transparent"] .ac_section_divider_top.p_polygon-1 {
      top: -3.9vw;
      z-index: 11; }
      .ac_section_divider[data-s-divider-type="semi-transparent"] .ac_section_divider_top.p_polygon-1 svg {
        height: 4vw; }
    .ac_section_divider[data-s-divider-type="semi-transparent"] .ac_section_divider_top.p_polygon-2 {
      top: -7vw;
      z-index: 10;
      opacity: .3; }
      .ac_section_divider[data-s-divider-type="semi-transparent"] .ac_section_divider_top.p_polygon-2 svg {
        height: 7vw; }
  .ac_section_divider[data-s-divider-type="smile"] .ac_section_divider_top {
    top: -10vw;
    position: absolute;
    z-index: 11;
    width: 100%; }
    .ac_section_divider[data-s-divider-type="smile"] .ac_section_divider_top svg {
      position: absolute;
      width: 100%;
      height: 10vw;
      transform: translateY(1px); }
  .ac_section_divider[data-s-divider-type="smile"] .ac_section_divider_bottom {
    bottom: -10vw;
    position: absolute;
    z-index: 11;
    width: 100%;
    transform: rotate(180deg); }
    .ac_section_divider[data-s-divider-type="smile"] .ac_section_divider_bottom svg {
      position: absolute;
      width: 100%;
      height: 10vw;
      transform: translateY(1px); }
  .ac_section_divider[data-s-divider-type="spiked"] .ac_section_divider_top {
    top: -19px;
    position: absolute;
    z-index: 11;
    width: 100%; }
  .ac_section_divider[data-s-divider-type="spiked"] .ac_section_divider_bottom {
    position: absolute;
    z-index: 11;
    width: 100%;
    bottom: -25px; }
    .ac_section_divider[data-s-divider-type="spiked"] .ac_section_divider_bottom svg {
      transform: rotate(180deg); }
  .ac_section_divider[data-s-divider-type="spiked"] svg {
    height: 20px;
    width: 100%; }
  .ac_section_divider[data-s-divider-type="spiked-small"] .ac_section_divider_top {
    top: -16px;
    position: absolute;
    z-index: 11;
    width: 100%; }
  .ac_section_divider[data-s-divider-type="spiked-small"] .ac_section_divider_bottom {
    position: absolute;
    z-index: 11;
    width: 100%;
    bottom: -20px; }
    .ac_section_divider[data-s-divider-type="spiked-small"] .ac_section_divider_bottom svg {
      transform: rotate(180deg); }
  .ac_section_divider[data-s-divider-type="spiked-small"] svg {
    height: 16px;
    width: 100%; }

/* PART - SECTION HEADING
--------------------------------------------------------------------------------------------*/
.ac_heading {
  margin: 0 auto; }
  @media print, screen and (min-width: 50em) {
    .ac_heading.v_title-centered_button-right .ac_heading_title {
      text-align: center; } }
  @media print, screen and (min-width: 50em) {
    .ac_heading.v_title-centered_button-right .ac_heading_link {
      position: absolute;
      right: 0;
      top: 1em; } }
  .ac_heading.v_title-centered_button-centered .ac_heading_link,
  .ac_heading.v_title-centered_button-centered .ac_heading_title {
    text-align: center; }
  .ac_heading.v_title-left_button-right .ac_heading_container {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.ac_heading_container {
  padding: 0 20px;
  position: relative; }

.ac_heading_title {
  margin-bottom: 1em; }

.ac_heading_link {
  white-space: nowrap; }

/* PART - SOCIALS
--------------------------------------------------------------------------------------------*/
.ac_socials {
  display: flex; }
  @media screen and (max-width: 39.9375em) {
    .ac_socials {
      justify-content: center; } }
  .ac_socials.v_smaller .ac_socials_link_icon {
    width: 3em;
    height: 3em;
    padding: 0.375em; }
    .ac_socials.v_smaller .ac_socials_link_icon svg {
      width: 2.25em;
      height: 2.25em; }
  .ac_socials.v_smallest .ac_socials_link_icon {
    width: 2em;
    height: 2em;
    padding: 0.25em; }
    .ac_socials.v_smallest .ac_socials_link_icon svg {
      width: 1.5em;
      height: 1.5em; }
  .ac_socials.v_hide_text .ac_socials_link_text {
    display: none; }
  @media screen and (max-width: 39.9375em) {
    .ac_socials.v_collapse-on-small {
      flex-direction: column; }
      .ac_socials.v_collapse-on-small .ac_socials_link {
        margin-bottom: 0.6em; } }

.ac_socials_link {
  display: flex;
  align-items: center;
  margin-right: 0.3em;
  margin-left: 0.3em; }

.ac_socials_link_icon {
  width: 4em;
  height: 4em;
  padding: 0.5em;
  background: #1D1D1B;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease; }
  .ac_socials_link_icon svg {
    width: 3em;
    height: 3em;
    fill: #ffffff;
    transition: all 0.3s ease; }

.ac_socials_link_text {
  margin-left: 0.6em; }

/* FOOTERS
--------------------------------------------------------------------------------------------*/
/* FOOTER 1 - 1
--------------------------------------------------------------------------------------------*/
[data-f-type="footer-1"] {
  /*  FOOTER 1 - GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/
  background: #353535;
  font-size: 90%; }
  [data-f-type="footer-1"] .ac_footer_primary {
    background: #F28C00; }
    [data-f-type="footer-1"] .ac_footer_primary * {
      color: #ffffff; }
  [data-f-type="footer-1"] .ac_footer_primary_container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 10px;
    display: flex;
    flex-wrap: wrap; }
    @media screen and (max-width: 64em) {
      [data-f-type="footer-1"] .ac_footer_primary_container {
        padding-bottom: 0; } }
  [data-f-type="footer-1"] .ac_footer_primary_column {
    padding: 0 10px;
    width: 100%; }
    @media screen and (max-width: 64em) {
      [data-f-type="footer-1"] .ac_footer_primary_column {
        margin-bottom: 40px; } }
    @media print, screen and (min-width: 50em) {
      [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(1) {
        flex: 0 1 20%;
        max-width: 20%; }
      [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(2) {
        flex: 0 1 40%;
        max-width: 40%; }
      [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(3) {
        flex: 0 1 20%;
        max-width: 20%; }
      [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(4) {
        flex: 0 1 20%;
        max-width: 20%; } }
    @media screen and (max-width: 49.9375em) {
      [data-f-type="footer-1"] .ac_footer_primary_column {
        text-align: center; } }
    @media screen and (max-width: 49.9375em) {
      [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(1) {
        display: none; }
      [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(3) {
        display: none; } }
  [data-f-type="footer-1"] .ac_footer_primary_link {
    color: white; }
    [data-f-type="footer-1"] .ac_footer_primary_link:hover {
      color: rgba(255, 255, 255, 0.7); }
  [data-f-type="footer-1"] .ac_footer_secondary {
    background: rgba(255, 255, 255, 0.1); }
    [data-f-type="footer-1"] .ac_footer_secondary * {
      color: rgba(255, 255, 255, 0.5); }
  [data-f-type="footer-1"] .ac_footer_secondary_container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  [data-f-type="footer-1"] .ac_footer_secondary_column {
    padding: 0 10px;
    width: 100%; }
    @media print, screen and (min-width: 50em) {
      [data-f-type="footer-1"] .ac_footer_secondary_column:nth-child(1) {
        flex: 0 1 50%;
        max-width: 50%; }
      [data-f-type="footer-1"] .ac_footer_secondary_column:nth-child(2) {
        flex: 0 1 50%;
        max-width: 50%; } }
    @media screen and (max-width: 49.9375em) {
      [data-f-type="footer-1"] .ac_footer_secondary_column {
        text-align: center;
        margin-bottom: 20px; }
        [data-f-type="footer-1"] .ac_footer_secondary_column .ac_footer_secondary_link,
        [data-f-type="footer-1"] .ac_footer_secondary_column .ac_footer_secondary_text {
          display: block; } }
    @media print, screen and (min-width: 50em) {
      [data-f-type="footer-1"] .ac_footer_secondary_column {
        display: flex;
        align-items: center; }
        [data-f-type="footer-1"] .ac_footer_secondary_column .ac_footer_secondary_link,
        [data-f-type="footer-1"] .ac_footer_secondary_column .ac_footer_secondary_text {
          margin-right: 10px; }
        [data-f-type="footer-1"] .ac_footer_secondary_column:nth-child(1) {
          justify-content: flex-start; }
        [data-f-type="footer-1"] .ac_footer_secondary_column:nth-child(2) {
          justify-content: flex-end; }
          [data-f-type="footer-1"] .ac_footer_secondary_column:nth-child(2) .ac_footer_secondary_link,
          [data-f-type="footer-1"] .ac_footer_secondary_column:nth-child(2) .ac_footer_secondary_text {
            margin-left: 10px; } }
  [data-f-type="footer-1"] .ac_footer_secondary_text {
    white-space: nowrap; }
  [data-f-type="footer-1"] .ac_footer_secondary_link {
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap; }
    [data-f-type="footer-1"] .ac_footer_secondary_link:hover {
      color: rgba(255, 255, 255, 0.9); }
  [data-f-type="footer-1"] .ac_socials {
    justify-content: center; }
  @media screen and (max-width: 49.9375em) {
    [data-f-type="footer-1"] .ac_socials {
      margin: 20px 0; } }
  [data-f-type="footer-1"] .ac_socials_link_icon {
    background: #353535; }
    [data-f-type="footer-1"] .ac_socials_link_icon svg {
      fill: #ffffff; }
    @media print, screen and (min-width: 64.0625em) {
      [data-f-type="footer-1"] .ac_socials_link_icon:hover {
        background: #ffffff; }
        [data-f-type="footer-1"] .ac_socials_link_icon:hover svg {
          fill: #353535; } }
  @media screen and (max-width: 49.9375em) {
    [data-f-type="footer-1"] {
      padding-bottom: 60px; } }
  @media screen and (min-width: 50em) and (max-width: 64em) {
    [data-f-type="footer-1"] {
      padding-bottom: 80px; } }
  [data-f-type="footer-1"] h2 {
    font-size: 160%;
    margin-bottom: 20px;
    opacity: .5; }
  [data-f-type="footer-1"] ul {
    list-style: none;
    margin: 0; }
  [data-f-type="footer-1"] .ac_footer_column:nth-child(2) .ac_footer_link {
    line-height: 1.3;
    margin-bottom: 20px; }
  [data-f-type="footer-1"] .ac_footer_primary_link {
    display: block; }
  [data-f-type="footer-1"] .ac_footer_primary_link_copy_above {
    text-transform: uppercase;
    display: block;
    opacity: .5;
    font-size: 80%; }
  [data-f-type="footer-1"] .ac_footer_secondary {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 90%; }
  [data-f-type="footer-1"] .ac_footer_secondary_link {
    display: block; }

/* MENUS
--------------------------------------------------------------------------------------------*/
/* SECTION - MENU 2
--------------------------------------------------------------------------------------------

// for menus we use a global scope, so we can address the body aswell

*/
/* MENU 2
--------------------------------------------------------------------------------------------*/
[data-m-type="menu-2"] {
  position: relative;
  background: #ffffff; }
  @media screen and (max-width: 49.9375em) {
    [data-m-type="menu-2"] {
      width: 100%;
      z-index: 2222;
      position: fixed;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); } }
  [data-m-type="menu-2"] ul.menu.dropdown {
    list-style: none;
    margin: 0;
    display: flex; }
    [data-m-type="menu-2"] ul.menu.dropdown a {
      margin-right: 20px; }
    [data-m-type="menu-2"] ul.menu.dropdown.v_has-items-right a {
      margin-right: inherit;
      margin-left: 20px; }
  [data-m-type="menu-2"] ul.is-dropdown-submenu {
    list-style: none;
    margin: 0;
    padding: .4em 0; }
    [data-m-type="menu-2"] ul.is-dropdown-submenu li.is-submenu-item {
      line-height: 2; }
    [data-m-type="menu-2"] ul.is-dropdown-submenu a {
      width: 100%;
      display: inline-block;
      padding: 0 1em; }
  [data-m-type="menu-2"] a {
    white-space: nowrap; }
  @media screen and (max-width: 49.9375em) {
    [data-m-type="menu-2"] + .main-container {
      padding-top: 70px; } }
  [data-m-type="menu-2"] .search-form {
    padding-right: 10px; }

.ac_menu-2_cta {
  display: flex;
  align-items: center; }

.ac_menu-2_cta_link {
  display: inline-flex;
  align-items: center; }
  .ac_menu-2_cta_link svg {
    width: 1.6em;
    height: 1.6em; }

.ac_menu-2_divider_after {
  position: relative;
  padding-right: 1em; }
  .ac_menu-2_divider_after:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 2px;
    border-right: solid 1px rgba(53, 53, 53, 0.1); }

.ac_menu-2_divider_before {
  position: relative;
  padding-left: 1em; }
  .ac_menu-2_divider_before:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    border-left: solid 1px rgba(53, 53, 53, 0.1); }

.ac_menu-2_sticky_container {
  margin: 0 auto;
  transition: height .3s ease; }

.ac_menu-2_sticky {
  width: 100%;
  z-index: 2221;
  transition: background-color .3s ease; }
  .ac_menu-2_sticky.is-stuck {
    background-color: #ffffff;
    z-index: 2222;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); }
    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
      width: 98px; }

.ac_menu-2_logo_container {
  padding: 20px 0; }
  @media screen and (max-width: 49.9375em) {
    .ac_menu-2_logo_container {
      padding: 0; } }
  @media print, screen and (min-width: 50em) {
    .ac_menu-2_logo_container {
      flex: 0 0 140px;
      margin-right: 20px; } }

a.ac_menu-2_logo_link {
  will-change: contents;
  transition: opacity .3s ease;
  margin: 0; }
  a.ac_menu-2_logo_link:hover {
    opacity: .8; }

.ac_menu-2_logo_img {
  width: 140px;
  transition: width .3s ease; }
  @media screen and (max-width: 49.9375em) {
    .ac_menu-2_logo_img {
      height: 50px;
      width: initial; } }

.ac_menu-2_bar_top {
  font-weight: 700; }
  @media screen and (max-width: 49.9375em) {
    .ac_menu-2_bar_top {
      display: none; } }
  .ac_menu-2_bar_top a {
    color: #ffffff; }

.ac_menu-2_bar_top {
  width: 100%;
  padding: 10px 20px;
  position: relative;
  z-index: 2223;
  background: #F28C00; }

.ac_menu-2_bar_top_container {
  padding: 0 20px;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }

.ac_menu-2_bar_top_content {
  line-height: 2; }

.ac_menu-2_main {
  position: relative;
  margin: 0 auto;
  display: flex;
  z-index: 2222;
  padding: 0 20px;
  max-width: 1000px;
  background: #ffffff; }
  @media screen and (max-width: 49.9375em) {
    .ac_menu-2_main {
      padding: 10px; } }

@media print, screen and (min-width: 50em) {
  .ac_menu-2_main_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity .3s ease, visibility 0s .6s; } }

.ac_menu-2_main_content {
  display: flex;
  justify-content: flex-end;
  align-items: baseline; }
  @media screen and (max-width: 49.9375em) {
    .ac_menu-2_main_content {
      display: none; } }

.ac_menu-2_bar_bottom {
  font-weight: 700; }
  @media screen and (max-width: 49.9375em) {
    .ac_menu-2_bar_bottom {
      display: none; } }

.ac_menu-2_bar_bottom {
  width: 100%;
  padding: 10px 20px;
  position: relative;
  z-index: 2223;
  background: #f0f0f0; }

.ac_menu-2_bar_bottom_container {
  padding: 0 20px;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }

.ac_menu-2_bar_bottom_content {
  line-height: 2; }

.ac_menu-2_btn-search {
  display: inline-flex;
  align-items: center; }
  .ac_menu-2_btn-search svg {
    width: 1.6em;
    height: 1.6em; }

.ac_menu-2_mobile_content {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center; }
  @media print, screen and (min-width: 50em) {
    .ac_menu-2_mobile_content {
      display: none; } }

.ac_menu-2_mobile_content_divider {
  width: 1px;
  margin: 0 0.7em;
  background: rgba(53, 53, 53, 0.1); }

.ac_menu-2_mobile_btn {
  height: 50px;
  width: 50px;
  padding: 12.5px 12.5px; }
  .ac_menu-2_mobile_btn svg {
    width: 25px !important;
    height: 25px !important;
    fill: #353535; }

.ac_menu-2_mobile_btn-hamburger {
  height: 42px;
  width: 45px;
  padding: 12px 10px; }
  .ac_menu-2_mobile_btn-hamburger .burger-icon {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .ac_menu-2_mobile_btn-hamburger .burger-icon, .ac_menu-2_mobile_btn-hamburger .burger-icon::before, .ac_menu-2_mobile_btn-hamburger .burger-icon::after {
      display: block;
      width: 25px;
      height: 2px;
      background-color: #F28C00;
      -webkit-transition-property: background-color, -webkit-transform;
      -moz-transition-property: background-color, -moz-transform;
      -o-transition-property: background-color, -o-transform;
      transition-property: background-color, transform;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s; }
    .ac_menu-2_mobile_btn-hamburger .burger-icon::before, .ac_menu-2_mobile_btn-hamburger .burger-icon::after {
      position: absolute;
      content: ""; }
    .ac_menu-2_mobile_btn-hamburger .burger-icon::before {
      top: -8px; }
    .ac_menu-2_mobile_btn-hamburger .burger-icon::after {
      top: 8px; }
  @media screen and (min-width: 64.0625em) {
    .ac_menu-2_mobile_btn-hamburger {
      display: none; } }

body.s_is-open_menu-mobile .burger-icon {
  background-color: transparent; }

body.s_is-open_menu-mobile .burger-icon::before {
  -webkit-transform: translateY(8px) rotate(45deg);
  -moz-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg); }

body.s_is-open_menu-mobile .burger-icon::after {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -moz-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg); }

body.s_is-open_menu-mobile .burger-icon:before {
  top: -6px; }

body.s_is-open_menu-mobile .burger-icon:after {
  top: 6px; }

.ac_menu-2_overlay_container {
  position: fixed;
  z-index: 2221;
  width: 400px;
  height: 100%;
  top: 0;
  right: 0;
  background: #f0f0f0;
  transform: translateX(400px);
  transition: transform .3s ease;
  padding-top: 70px; }
  @media screen and (max-width: 39.9375em) {
    .ac_menu-2_overlay_container {
      width: 90vw;
      transform: translateX(100%); } }
  @media screen and (min-width: 64.0625em) {
    .ac_menu-2_overlay_container {
      display: none; } }

.ac_menu-2_overlay_scroll_container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%; }

.ac_menu-2_overlay_scroll_container_inner {
  min-height: 100vh; }

.ac_menu-2_overlay_menu_container {
  padding: 20px; }
  .ac_menu-2_overlay_menu_container ul {
    flex-direction: column;
    padding-bottom: 2em; }
    .ac_menu-2_overlay_menu_container ul a {
      display: block;
      text-align: left;
      margin-right: 0;
      color: rgba(29, 29, 27, 0.8) !important;
      font-weight: 700 !important;
      padding: 10px 0; }
    .ac_menu-2_overlay_menu_container ul > li {
      border-bottom: 1px solid rgba(16, 16, 15, 0.4); }
      .ac_menu-2_overlay_menu_container ul > li.current-menu-item a {
        color: #1d1d1b !important; }
      .ac_menu-2_overlay_menu_container ul > li:last-child {
        border-bottom: 0; }
      .ac_menu-2_overlay_menu_container ul > li > a {
        padding: 10px 0;
        text-transform: uppercase; }
    .ac_menu-2_overlay_menu_container ul ul.is-accordion-submenu {
      list-style: none;
      margin-left: 1em;
      margin-bottom: 1em; }
      .ac_menu-2_overlay_menu_container ul ul.is-accordion-submenu li {
        width: 100%; }
      .ac_menu-2_overlay_menu_container ul ul.is-accordion-submenu a {
        font-size: 90%;
        padding: 5px 0; }
  .ac_menu-2_overlay_menu_container .ac_socials {
    display: flex;
    align-items: center; }

body.s_is-open_menu-mobile {
  overflow: hidden; }
  body.s_is-open_menu-mobile .ac_menu-2_overlay_container {
    transform: translateX(0); }

.ac_menu-2_overlay_backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.8);
  z-index: 2220;
  height: 0;
  opacity: 0;
  transition: opacity .3s ease .3s, height 0s .6s; }
  @media screen and (min-width: 64.0625em) {
    .ac_menu-2_overlay_backdrop {
      display: none; } }

body.s_is-open_menu-mobile .ac_menu-2_overlay_backdrop {
  height: 100%;
  opacity: 1;
  transition: height 0s, opacity .3s ease; }

.ac_menu-2_search_container {
  position: absolute;
  top: 50%;
  right: 0;
  width: 50%;
  transform: translateY(-50%) translateX(-2em);
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease; }
  @media screen and (max-width: 64em) {
    .ac_menu-2_search_container {
      top: 50%;
      right: 1em; } }

body.IE10 .ac_menu-2_search_container {
  display: none; }

.ac_menu-2_search_input {
  margin: 0;
  font-size: .9rem; }

.ac_menu-2_search_close {
  position: absolute;
  right: .7em;
  top: 0;
  width: 3em;
  height: 3em;
  padding: .7em; }
  .ac_menu-2_search_close svg {
    width: 1.6em;
    height: 1.6em;
    fill: #353535; }

body.s_is-open_menu-search.IE10 .ac_menu-2_search_container {
  display: block; }

body.s_is-open_menu-search .ac_menu-2_search_container {
  transform: translateX(0) translateY(-50%);
  opacity: 1;
  pointer-events: auto; }
  @media screen and (max-width: 64em) {
    body.s_is-open_menu-search .ac_menu-2_search_container {
      transform: translateX(0) translateY(-50%); } }

.ac_socials_link_icon {
  background: #ffffff; }
  .ac_socials_link_icon svg {
    fill: #353535; }
  @media print, screen and (min-width: 64.0625em) {
    .ac_socials_link_icon:hover {
      background: #353535; }
      .ac_socials_link_icon:hover svg {
        fill: #ffffff; } }

/*--------------------------------------------------------------------------------------------*/
/* MENU-101_1
--------------------------------------------------------------------------------------------*/
/* AC_menu-101
--------------------------------------------------------------------------------------------*/
[data-m-type="menu-101"] {
  width: 180px;
  z-index: 111;
  background: #ffffff; }
  @media screen and (max-width: 49.9375em) {
    [data-m-type="menu-101"] {
      width: 100%;
      position: fixed; } }
  @media print, screen and (min-width: 50em) {
    [data-m-type="menu-101"] {
      position: fixed; } }
  [data-m-type="menu-101"] ul.menu.vertical,
  [data-m-type="menu-101"] ul.menu.dropdown {
    list-style: none;
    margin-left: 0; }
    [data-m-type="menu-101"] ul.menu.vertical a,
    [data-m-type="menu-101"] ul.menu.dropdown a {
      display: block; }
  [data-m-type="menu-101"] ul.menu.is-dropdown-submenu {
    margin-left: 1em;
    padding: .4em 0;
    box-shadow: 5px 5px 10px rgba(29, 29, 27, 0.1); }
    [data-m-type="menu-101"] ul.menu.is-dropdown-submenu a {
      padding: .1em 1.2em; }
  [data-m-type="menu-101"] ul.is-accordion-submenu {
    list-style: none; }
  [data-m-type="menu-101"] a:hover {
    color: #a56000fa; }
  @media screen and (max-width: 49.9375em) {
    [data-m-type="menu-101"] + .main-container {
      padding-top: 70px; } }
  @media print, screen and (min-width: 50em) {
    [data-m-type="menu-101"] + .main-container {
      padding-left: 180px; } }

@media screen and (max-width: 49.9375em) {
  .ac_menu-101_container {
    display: flex;
    padding: 10px;
    position: relative;
    z-index: 111;
    background: #ffffff; } }

.ac_menu-101_logo_container {
  padding: 0; }

a.ac_menu-101_logo_link {
  will-change: contents;
  transition: opacity .3s ease; }
  a.ac_menu-101_logo_link:hover {
    opacity: .8; }

@media print, screen and (min-width: 50em) {
  .ac_menu-101_logo_mobile_container {
    display: none; } }

@media screen and (max-width: 49.9375em) {
  .ac_menu-101_logo_mobile_img {
    max-height: 50px;
    width: initial; } }

.ac_menu-101_main {
  display: flex;
  width: 180px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); }
  @media print, screen and (min-width: 50em) {
    .ac_menu-101_main {
      position: absolute;
      flex-direction: column;
      height: 100vh;
      padding: 20px;
      justify-content: space-between; } }
  @media screen and (min-width: 50em) and (max-width: 64em) {
    .ac_menu-101_main {
      padding-bottom: 110px; } }
  @media screen and (max-width: 49.9375em) {
    .ac_menu-101_main {
      display: none; } }

.ac_menu-101_main_menu {
  margin-top: 2em; }

.ac_menu-101_mobile_content {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1; }
  @media print, screen and (min-width: 50em) {
    .ac_menu-101_mobile_content {
      display: none; } }

.ac_menu-101_mobile_content_divider {
  width: 1px;
  margin: 0 1em;
  background: #f0f0f0; }

.ac_menu-101_mobile_btn {
  height: 50px;
  width: 50px;
  padding: 12.5px 12.5px; }
  .ac_menu-101_mobile_btn svg {
    width: 25px !important;
    height: 25px !important; }

.ac_menu-101_mobile_btn-hamburger {
  height: 50px;
  width: 50px;
  padding: 12px 10px; }
  .ac_menu-101_mobile_btn-hamburger .burger-icon {
    position: relative;
    margin-top: 6px;
    margin-bottom: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .ac_menu-101_mobile_btn-hamburger .burger-icon, .ac_menu-101_mobile_btn-hamburger .burger-icon::before, .ac_menu-101_mobile_btn-hamburger .burger-icon::after {
      display: block;
      width: 25px;
      height: 2px;
      background-color: #1D1D1B;
      -webkit-transition-property: background-color, -webkit-transform;
      -moz-transition-property: background-color, -moz-transform;
      -o-transition-property: background-color, -o-transform;
      transition-property: background-color, transform;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s; }
    .ac_menu-101_mobile_btn-hamburger .burger-icon::before, .ac_menu-101_mobile_btn-hamburger .burger-icon::after {
      position: absolute;
      content: ""; }
    .ac_menu-101_mobile_btn-hamburger .burger-icon::before {
      top: -6px; }
    .ac_menu-101_mobile_btn-hamburger .burger-icon::after {
      top: 6px; }
  @media print, screen and (min-width: 50em) {
    .ac_menu-101_mobile_btn-hamburger {
      display: none; } }

body.s_is-open_menu-mobile .burger-icon {
  background-color: transparent; }

body.s_is-open_menu-mobile .burger-icon::before {
  -webkit-transform: translateY(6px) rotate(45deg);
  -moz-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg); }

body.s_is-open_menu-mobile .burger-icon::after {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -moz-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg); }

.ac_menu-101_overlay_container {
  position: fixed;
  z-index: 110;
  width: 400px;
  height: 100%;
  top: 0;
  right: 0;
  background: #fafafd;
  transform: translateX(400px);
  transition: transform .3s ease;
  padding-top: 70px; }
  @media screen and (max-width: 39.9375em) {
    .ac_menu-101_overlay_container {
      width: 90vw;
      transform: translateX(100%); } }
  @media print, screen and (min-width: 50em) {
    .ac_menu-101_overlay_container {
      display: none; } }

.ac_menu-101_overlay_scroll_container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%; }

.ac_menu-101_overlay_scroll_container_inner {
  min-height: 100vh; }

.ac_menu-101_overlay_menu_container {
  padding: 20px; }

ul.ac_menu-101_overlay_menu_primary {
  list-style: none;
  margin: 0;
  border-top: solid 1px rgba(102, 102, 102, 0.2);
  padding-bottom: 2em; }
  ul.ac_menu-101_overlay_menu_primary a {
    display: block;
    text-align: center; }
  ul.ac_menu-101_overlay_menu_primary > li {
    border-bottom: solid 1px rgba(102, 102, 102, 0.2); }
    ul.ac_menu-101_overlay_menu_primary > li > a {
      padding: 10px 0; }
  ul.ac_menu-101_overlay_menu_primary ul.is-accordion-submenu {
    list-style: none;
    margin-left: 1em;
    margin-bottom: 1em; }
    ul.ac_menu-101_overlay_menu_primary ul.is-accordion-submenu a {
      padding: 5px 0; }

ul.ac_menu-101_overlay_menu_secondary {
  list-style: none;
  margin: 0; }
  ul.ac_menu-101_overlay_menu_secondary a {
    display: block;
    text-align: center;
    padding: 5px 0; }

body.s_is-open_menu-mobile {
  overflow: hidden; }
  body.s_is-open_menu-mobile .ac_menu-101_overlay_container {
    transform: translateX(0); }

.ac_menu-101_overlay_backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(16, 16, 15, 0.8);
  z-index: 109;
  height: 0;
  opacity: 0;
  transition: opacity .3s ease .3s, height 0s .6s; }
  @media print, screen and (min-width: 50em) {
    .ac_menu-101_overlay_backdrop {
      display: none; } }

body.s_is-open_menu-mobile .ac_menu-101_overlay_backdrop {
  height: 100%;
  opacity: 1;
  transition: height 0s, opacity .3s ease; }

.ac_menu-101_search_container {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  transform: translateX(2em);
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease; }
  @media screen and (max-width: 49.9375em) {
    .ac_menu-101_search_container {
      top: 50%;
      right: 1em;
      transform: translateY(-50%) translateX(-2em); } }

body.IE10 .ac_menu-101_search_container {
  display: none; }

.ac_menu-101_search_input {
  margin: 0; }

.ac_menu-101_search_close {
  position: absolute;
  right: .7em;
  top: 0;
  width: 3em;
  height: 3em;
  padding: .7em; }
  .ac_menu-101_search_close svg {
    width: 1.6em;
    height: 1.6em;
    fill: #353535; }

body.s_is-open_menu-search.IE10 .ac_menu-101_search_container {
  display: block; }

body.s_is-open_menu-search .ac_menu-101_search_container {
  transform: translateX(0) translateY(0);
  opacity: 1;
  pointer-events: auto; }
  @media screen and (max-width: 49.9375em) {
    body.s_is-open_menu-search .ac_menu-101_search_container {
      transform: translateX(0) translateY(-50%); } }

.ac_socials_link_icon {
  background: #353535; }
  .ac_socials_link_icon svg {
    fill: #ffffff; }
  @media print, screen and (min-width: 64.0625em) {
    .ac_socials_link_icon:hover {
      background: rgba(53, 53, 53, 0.1); }
      .ac_socials_link_icon:hover svg {
        fill: #353535; } }

/* SECTIONS
--------------------------------------------------------------------------------------------*/
/* PARTNERS - 1
--------------------------------------------------------------------------------------------*/
/* AC_S_PARTNERS - GLOBALS
--------------------------------------------------------------------------------------------*/
[data-s-type="partners"] {
  position: relative;
  padding: 20px 0; }
  @media print, screen and (min-width: 50em) {
    [data-s-type="partners"] {
      padding: 0 0 10px 0; } }
  [data-s-type="partners"].v_is-slider.v_has-dots .ac_brands_container {
    margin-bottom: 4em; }
  [data-s-type="partners"].v_is-slider .slick-dots {
    bottom: -4em; }

.ac_partners_container {
  position: relative;
  z-index: 2;
  margin: 2em auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px; }

.ac_partners_item {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 0;
  width: 50%;
  max-width: 50%;
  padding: 0 20px;
  padding: 0 10px; }
  .ac_partners_item.v_smaller-logo .ac_partners_item_logo {
    transform: scale(0.8); }
  .ac_partners_item.v_larger-logo .ac_partners_item_logo {
    transform: scale(1.2); }

@media print, screen and (min-width: 64.0625em) {
  a.ac_partners_item:hover .ac_partners_item_logo {
    opacity: 0.6; }
  a.ac_partners_item:hover .ac_partners_item_description {
    color: #353535; } }

.ac_partners_item_container {
  height: 100%;
  position: relative;
  background: none;
  padding: 0; }

.ac_partners_item_description {
  display: none; }

.ac_partners_item_logo_container {
  display: flex;
  width: 100%;
  justify-content: center; }
  @media print, screen and (min-width: 40em) {
    .ac_partners_item_logo_container {
      padding: 0; } }

.ac_partners_item_logo {
  width: 60%;
  height: 100px;
  max-width: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: opacity 0.3s ease;
  opacity: 1; }

[data-s-type="partners"].v_is-slider .ac_partners_container {
  display: block;
  z-index: inherit;
  max-height: 100px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease; }
  [data-s-type="partners"].v_is-slider .ac_partners_container.slick-initialized {
    max-height: 100px;
    overflow: visible;
    opacity: 1; }

[data-s-type="partners"].v_is-slider [data-s-amount-item] {
  margin-bottom: 0; }

[data-s-type="partners"].v_is-slider [data-border-bottom] {
  border-bottom-color: transparent; }

@media screen and (max-width: 39.9375em) {
  [data-s-type="partners"].v_is-slider [data-s-amount-item] {
    border-left-color: transparent; } }

/*--------------------------------------------------------------------------------------------*/
[data-s-type="partners"] .slick-track {
  display: flex; }

/* CTA
--------------------------------------------------------------------------------------------*/
[data-s-type="cta"] {
  /* AC_S_CTA - GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="cta"] .ac_cta_container {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 20px; }
    [data-s-type="cta"] .ac_cta_container p {
      line-height: 1.5; }
  [data-s-type="cta"] .ac_cta_column {
    width: 100%; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="cta"] .ac_cta_column .button {
        margin-right: 10px;
        margin-bottom: 0px; } }
    [data-s-type="cta"] .ac_cta_column img {
      border-bottom: solid 1px #ffffff;
      max-width: 220px; }
  [data-s-type="cta"] h2 {
    margin-bottom: 20px; }
  [data-s-type="cta"] .ac_cta_column {
    width: 100%;
    text-align: center; }

/* COOKIE MESSAGE - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="cookie-message"] {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 6666;
  padding: 20px 0;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: transform 0.3s ease; }

body.s_is-open_cookie-message [data-s-type="cookie-message"] {
  opacity: 1;
  pointer-events: initial;
  transform: translateY(0); }

/* CTA TEXT-BUTTON
--------------------------------------------------------------------------------------------*/
[data-s-type="cta-text-button"] {
  /* AC_S_CTA - GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="cta-text-button"] .ac_cta_container {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 20px; }
    [data-s-type="cta-text-button"] .ac_cta_container p {
      line-height: 1.5; }
  [data-s-type="cta-text-button"] .ac_cta_column {
    width: 100%; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="cta-text-button"] .ac_cta_column .button {
        margin-right: 10px;
        margin-bottom: 0px; } }
    [data-s-type="cta-text-button"] .ac_cta_column img {
      border-bottom: solid 1px #ffffff;
      max-width: 220px; }
  [data-s-type="cta-text-button"] h2 {
    margin-bottom: 0; }
  @media print, screen and (min-width: 50em) {
    [data-s-type="cta-text-button"] .ac_cta_container {
      display: flex;
      align-items: center; } }
  [data-s-type="cta-text-button"] .ac_cta_column {
    width: 100%;
    text-align: center; }
    [data-s-type="cta-text-button"] .ac_cta_column:nth-child(1) {
      padding-bottom: 20px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      margin-bottom: 40px; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="cta-text-button"] .ac_cta_column:nth-child(1) {
        width: 50%;
        max-width: 50%;
        padding-right: 20px;
        padding-bottom: inherit;
        border-bottom: inherit;
        margin-bottom: inherit;
        border-right: solid 1px rgba(255, 255, 255, 0.2);
        margin-right: 20px;
        text-align: left;
        text-align: right; }
      [data-s-type="cta-text-button"] .ac_cta_column:nth-child(2) {
        width: 50%;
        max-width: 50%;
        text-align: left; } }
  [data-s-type="cta-text-button"] .ac_socials {
    justify-content: flex-end; }

/* CTA TEXT-IMAGE
--------------------------------------------------------------------------------------------*/
[data-s-type="cta-text-image"] {
  /* AC_S_CTA - GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="cta-text-image"] .ac_cta_container {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 20px; }
    [data-s-type="cta-text-image"] .ac_cta_container p {
      line-height: 1.5; }
  [data-s-type="cta-text-image"] .ac_cta_column {
    width: 100%; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="cta-text-image"] .ac_cta_column .button {
        margin-right: 10px;
        margin-bottom: 0px; } }
    [data-s-type="cta-text-image"] .ac_cta_column img {
      border-bottom: solid 1px rgba(255, 255, 255, 0.3);
      max-width: 220px; }
  @media print, screen and (min-width: 50em) {
    [data-s-type="cta-text-image"] .ac_cta_container {
      display: flex;
      align-items: center; } }
  [data-s-type="cta-text-image"] .ac_cta_column {
    width: 100%;
    text-align: center; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="cta-text-image"] .ac_cta_column:nth-child(1) {
        width: 60%;
        max-width: 60%;
        padding-right: 20px;
        padding-bottom: inherit;
        text-align: left;
        text-align: right; }
      [data-s-type="cta-text-image"] .ac_cta_column:nth-child(2) {
        width: 40%;
        max-width: 40%;
        text-align: left; } }
  [data-s-type="cta-text-image"] .ac_socials {
    justify-content: flex-end; }

/* CTA TEXT-BUTTON
--------------------------------------------------------------------------------------------*/
[data-s-type="cta-text-socials"] {
  /* AC_S_CTA - GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="cta-text-socials"] .ac_cta_container {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 20px; }
    [data-s-type="cta-text-socials"] .ac_cta_container p {
      line-height: 1.5; }
  [data-s-type="cta-text-socials"] .ac_cta_column {
    width: 100%; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="cta-text-socials"] .ac_cta_column .button {
        margin-right: 10px;
        margin-bottom: 0px; } }
    [data-s-type="cta-text-socials"] .ac_cta_column img {
      border-bottom: solid 1px #ffffff;
      max-width: 220px; }
  [data-s-type="cta-text-socials"] h2 {
    margin-bottom: 20px; }
  [data-s-type="cta-text-socials"] .ac_cta_column {
    width: 100%;
    text-align: center; }
    [data-s-type="cta-text-socials"] .ac_cta_column:nth-child(1) {
      display: none; }
  [data-s-type="cta-text-socials"] .ac_socials {
    justify-content: space-around;
    flex-wrap: wrap; }
  [data-s-type="cta-text-socials"] .ac_socials_link {
    padding: 10px 20px; }
    @media screen and (max-width: 39.9375em) {
      [data-s-type="cta-text-socials"] .ac_socials_link {
        width: 100%; } }

/* HERO - 2
--------------------------------------------------------------------------------------------*/
[data-s-type="hero"] {
  /* AC_S_HERO - GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="hero"] .ac_hero_container {
    margin: 0 auto;
    height: 30vh;
    min-height: 500px;
    position: relative;
    display: block; }
    [data-s-type="hero"] .ac_hero_container:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 25vh;
      background: linear-gradient(0deg, rgba(48, 48, 48, 0), #303030);
      z-index: 1; }
  [data-s-type="hero"] .ac_hero_slides {
    position: relative;
    z-index: 3;
    height: 100%;
    display: block;
    margin: 0 auto;
    max-height: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease; }
    [data-s-type="hero"] .ac_hero_slides.slick-initialized {
      max-height: inherit;
      opacity: 1; }
  [data-s-type="hero"] .ac_hero_slide {
    will-change: contents;
    position: relative; }
  [data-s-type="hero"] .ac_hero_slide_inner {
    max-width: 800px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    will-change: contents;
    padding: 0 20px; }
    @media screen and (min-width: 1150px) {
      [data-s-type="hero"] .ac_hero_slide_inner {
        padding: 0 20px; } }
  [data-s-type="hero"] .ac_hero_slide_content_container {
    transform: translateY(-3em);
    will-change: contents; }
  [data-s-type="hero"] .ac_hero_slide_content {
    display: flex; }
  [data-s-type="hero"] .ac_hero_slide_content_inner {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center; }
  [data-s-type="hero"] .ac_hero_slide_content_text {
    flex: 0 1 100%;
    max-width: 100%; }
    @media print, screen and (min-width: 40em) {
      [data-s-type="hero"] .ac_hero_slide_content_text {
        flex: 0 1 70%;
        max-width: 70%; } }
  [data-s-type="hero"] .ac_hero_slide_content_image {
    display: flex;
    justify-content: center;
    flex: 0 1 30%;
    max-width: 30%; }
    [data-s-type="hero"] .ac_hero_slide_content_image img {
      max-width: initial; }
    @media screen and (max-width: 39.9375em) {
      [data-s-type="hero"] .ac_hero_slide_content_image {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0.4;
        max-width: initial; } }
  [data-s-type="hero"] .slick-dots button {
    border: solid 2px rgba(255, 255, 255, 0.5);
    background: transparent; }
  [data-s-type="hero"] .slick-dots .slick-active button {
    border: solid 2px rgba(255, 255, 255, 0.5);
    background: #ffffff; }
  [data-s-type="hero"] .slick-list {
    height: 100%; }
  [data-s-type="hero"] .slick-track {
    height: 100%; }
  [data-s-type="hero"] .slick-prev,
  [data-s-type="hero"] .slick-next {
    display: none !important;
    background: none;
    padding: 0;
    box-shadow: none;
    width: 1.5em;
    height: 1.5em; }
    @media screen and (min-width: 1150px) {
      [data-s-type="hero"] .slick-prev,
      [data-s-type="hero"] .slick-next {
        width: 2em;
        height: 2em; } }
    [data-s-type="hero"] .slick-prev svg,
    [data-s-type="hero"] .slick-next svg {
      fill: #ffffff;
      width: 1.5em;
      height: 1.5em;
      transition: all 0.3s ease; }
      @media screen and (min-width: 1150px) {
        [data-s-type="hero"] .slick-prev svg,
        [data-s-type="hero"] .slick-next svg {
          width: 2em;
          height: 2em; } }
    @media print, screen and (min-width: 64.0625em) {
      [data-s-type="hero"] .slick-prev:hover svg,
      [data-s-type="hero"] .slick-next:hover svg {
        fill: rgba(255, 255, 255, 0.5); } }
    @media screen and (min-width: 700px) {
      [data-s-type="hero"] .slick-prev,
      [data-s-type="hero"] .slick-next {
        display: block !important; } }
  [data-s-type="hero"] .slick-prev {
    top: initial;
    bottom: 10em;
    left: 50%;
    transform: translateX(-10.5em); }
  [data-s-type="hero"] .slick-next {
    top: initial;
    bottom: 10em;
    left: 50%;
    transform: translateX(9em); }
  [data-s-type="hero"] .slick-dots {
    bottom: 10em; }
  [data-s-type="hero"] .ac_hero_link_to-content {
    position: absolute;
    z-index: 1111;
    bottom: 4em;
    left: 50%;
    transform: translateX(-50%);
    display: none; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="hero"] .ac_hero_link_to-content {
        display: block; } }
    @media print, screen and (min-width: 64.0625em) {
      [data-s-type="hero"] .ac_hero_link_to-content:hover {
        cursor: pointer; } }
    [data-s-type="hero"] .ac_hero_link_to-content .svg-container {
      width: 4em;
      height: 4em; }
    [data-s-type="hero"] .ac_hero_link_to-content svg {
      width: 4em;
      height: 4em;
      fill: #ffffff;
      transition: all 0.3s ease; }
    [data-s-type="hero"] .ac_hero_link_to-content:active {
      transform: translateX(-50%) translateY(2px); }
    [data-s-type="hero"] .ac_hero_link_to-content:hover svg {
      fill: rgba(255, 255, 255, 0.5); }
  [data-s-type="hero"] h1, [data-s-type="hero"] h2, [data-s-type="hero"] h3 {
    line-height: 1; }
  [data-s-type="hero"] p {
    line-height: 1.5; }
  [data-s-type="hero"] *:focus {
    outline: none; }
  @media screen and (max-width: 49.9375em) {
    [data-s-type="hero"] .ac_hero_container {
      height: 50vh;
      min-height: 400px; } }
  @media screen and (max-width: 39.9375em) {
    [data-s-type="hero"] .ac_hero_slide_content_container {
      transform: translateY(-1.5em); } }
  [data-s-type="hero"] .ac_hero_slide_content_text {
    color: #ffffff; }
  [data-s-type="hero"] .ac_hero_slide_content_text_title * {
    font-size: 140%;
    color: #ffffff; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="hero"] .ac_hero_slide_content_text_title * {
        font-size: 180%; } }
  @media screen and (max-width: 39.9375em) {
    [data-s-type="hero"] .slick-dots {
      bottom: 2em; } }

/* AC_S_HERO_ALT - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="hero-alt"] {
  /* AC_S_HERO - GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/
  position: relative; }
  [data-s-type="hero-alt"] .ac_hero_alt_container {
    margin: 0 auto;
    height: 150px;
    min-height: 200px;
    position: relative;
    display: block; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="hero-alt"] .ac_hero_alt_container {
        height: 300px; } }
  [data-s-type="hero-alt"] .ac_hero_alt_slides {
    position: relative;
    z-index: 3;
    height: 100%;
    display: block;
    margin: 0 auto;
    max-height: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease; }
    [data-s-type="hero-alt"] .ac_hero_alt_slides.slick-initialized {
      max-height: inherit;
      opacity: 1; }
  [data-s-type="hero-alt"] .ac_hero_alt_slide {
    will-change: contents;
    position: relative; }
  [data-s-type="hero-alt"] .ac_hero_alt_slide_inner {
    max-width: 1000px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    will-change: contents;
    padding: 0 20px; }
    @media screen and (min-width: 0px) {
      [data-s-type="hero-alt"] .ac_hero_alt_slide_inner {
        padding: 0 80px; } }
  [data-s-type="hero-alt"] .ac_hero_alt_slide_content_container {
    will-change: contents; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="hero-alt"] .ac_hero_alt_slide_content_container {
        transform: translateY(-1em); } }
  [data-s-type="hero-alt"] .ac_hero_alt_slide_content {
    display: flex; }
  [data-s-type="hero-alt"] .ac_hero_alt_slide_content_inner {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center; }
  [data-s-type="hero-alt"] .ac_hero_alt_slide_content_text {
    flex: 0 1 100%;
    max-width: 100%;
    text-align: center; }
    @media print, screen and (min-width: 40em) {
      [data-s-type="hero-alt"] .ac_hero_alt_slide_content_text {
        flex: 0 1 80%;
        max-width: 80%; } }
  [data-s-type="hero-alt"] .ac_hero_alt_slide_content_image {
    display: flex;
    justify-content: center;
    display: none; }
  [data-s-type="hero-alt"] .slick-list {
    height: 100%; }
  [data-s-type="hero-alt"] .slick-track {
    height: 100%; }
  [data-s-type="hero-alt"] .slick-prev,
  [data-s-type="hero-alt"] .slick-next {
    display: none !important;
    background: none;
    padding: 0;
    box-shadow: none;
    width: 1em;
    height: 1em; }
    @media screen and (min-width: 1150px) {
      [data-s-type="hero-alt"] .slick-prev,
      [data-s-type="hero-alt"] .slick-next {
        display: block !important; } }
    @media screen and (min-width: 1150px) {
      [data-s-type="hero-alt"] .slick-prev,
      [data-s-type="hero-alt"] .slick-next {
        width: 2em;
        height: 2em; } }
    [data-s-type="hero-alt"] .slick-prev svg,
    [data-s-type="hero-alt"] .slick-next svg {
      fill: white;
      width: 1em;
      height: 1em; }
      @media screen and (min-width: 1150px) {
        [data-s-type="hero-alt"] .slick-prev svg,
        [data-s-type="hero-alt"] .slick-next svg {
          width: 2em;
          height: 2em; } }
  [data-s-type="hero-alt"] .slick-prev {
    top: initial;
    bottom: 4em;
    left: 50%;
    transform: translateX(6em); }
  [data-s-type="hero-alt"] .slick-next {
    top: initial;
    bottom: 4em;
    right: 50%;
    transform: translateX(-6em); }
  [data-s-type="hero-alt"] .slick-dots {
    bottom: 4em; }
  [data-s-type="hero-alt"] .ac_hero_alt_backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden; }
  [data-s-type="hero-alt"] .ac_hero_alt_backdrop_image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  [data-s-type="hero-alt"] .ac_hero_alt_backdrop_image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 0.4;
    mix-blend-mode: multiply; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="hero-alt"] .ac_hero_alt_backdrop_image {
        opacity: 1;
        mix-blend-mode: normal;
        filter: grayscale(0%); } }
  [data-s-type="hero-alt"] .ac_hero_alt_backdrop_video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2; }
    [data-s-type="hero-alt"] .ac_hero_alt_backdrop_video-container video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      transform: translateX(-50%) translateY(-50%); }
  [data-s-type="hero-alt"] .ac_hero_alt_link_to-content {
    display: none; }
  [data-s-type="hero-alt"] h1, [data-s-type="hero-alt"] h2, [data-s-type="hero-alt"] h3 {
    line-height: 1; }
  [data-s-type="hero-alt"] p {
    line-height: 1.5; }
  [data-s-type="hero-alt"] *:focus {
    outline: none; }

/* IMAGE - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="image"] {
  /* IMAGE - GLOBALS
--------------------------------------------------------------------------------------------*/
  position: relative;
  /*--------------------------------------------------------------------------------------------*/ }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="image"] .ac_image {
      display: none; } }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="image"] {
      min-height: 300px; } }

/* IMAGE - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="image-bg-fixed"] {
  /* IMAGE - GLOBALS
--------------------------------------------------------------------------------------------*/
  position: relative;
  background: linear-gradient(135deg, #a56000fa, #F28C00);
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="image-bg-fixed"] .ac_image {
    position: absolute;
    width: 100%;
    height: 100%; }
    [data-s-type="image-bg-fixed"] .ac_image figure {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: .2;
      background-attachment: fixed; }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="image-bg-fixed"] {
      height: 300px; } }

/* AC_S_IMAGE-GALLERY_FULL-SCREEN - 1
--------------------------------------------------------------------------------------------*/
/* AC_S_IMAGE-GALLERY_FULL-SCREEN - GLOBALS
--------------------------------------------------------------------------------------------*/
[data-s-type="image-gallery_full-screen"] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4444;
  width: 100%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, height 0s linear 0.3s, visibility 0s linear 0.3s; }

body.s_is-open_image-gallery_full-screen [data-s-type="image-gallery_full-screen"] {
  height: 100%;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear, height 0s linear, opacity 0.3s ease 0.3s; }

.ac_image-gallery_full-screen_container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.image-gallery_full-screen_item_container {
  position: relative; }

.image-gallery_full-screen_item_content {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: rgba(53, 53, 53, 0.9); }

.image-gallery_full-screen_item_content_inner {
  margin: 0 auto;
  max-width: 1000px;
  padding: 20px;
  color: #ffffff; }

.image-gallery_full-screen_item_image_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; }

.ac_image-gallery_full-screen_close {
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 4445;
  top: 20px;
  right: 20px;
  display: none;
  padding: 12px;
  background: #ffffff;
  border-radius: 3px;
  transition: all 0.3s ease; }
  @media print, screen and (min-width: 50em) {
    .ac_image-gallery_full-screen_close {
      top: 40px;
      right: 40px; } }
  .ac_image-gallery_full-screen_close svg {
    width: 16px;
    height: 16px;
    fill: #353535;
    transition: all 0.3s ease; }
  .ac_image-gallery_full-screen_close:hover {
    cursor: pointer; }
    @media print, screen and (min-width: 64.0625em) {
      .ac_image-gallery_full-screen_close:hover {
        background: #353535; }
        .ac_image-gallery_full-screen_close:hover svg {
          fill: #ffffff; } }

body.s_is-open_image-gallery_full-screen .ac_image-gallery_full-screen_close {
  display: block; }

.ac_image-gallery_full-screen_background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4443;
  width: 100%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, height 0s linear 0.3s, visibility 0s linear 0.3s;
  background: rgba(53, 53, 53, 0.9); }

body.s_is-open_image-gallery_full-screen .ac_image-gallery_full-screen_background {
  height: 100%;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear, height 0s linear,  opacity .2s ease .2s; }

/* AC_S_IMAGE-GALLERY-GRID - 1
--------------------------------------------------------------------------------------------*/
/* AC_S_IMAGE-GALLERY - GLOBALS
--------------------------------------------------------------------------------------------*/
[data-s-type="image-gallery-grid"] .ac_image-gallery_grid_container:nth-child(even) {
  flex-direction: row-reverse; }
  [data-s-type="image-gallery-grid"] .ac_image-gallery_grid_container:nth-child(even) .ac_image-gallery_grid_outer-column:nth-child(1) {
    flex-direction: row-reverse; }
  [data-s-type="image-gallery-grid"] .ac_image-gallery_grid_container:nth-child(even) .ac_image-gallery_grid_outer-column:nth-child(2) .ac_image-gallery_grid_column {
    justify-content: flex-end; }

.ac_image-gallery_grid_container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto; }

.ac_image-gallery_grid_outer-column {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  @media screen and (min-width: 800px) {
    .ac_image-gallery_grid_outer-column:nth-child(1) {
      width: 60%;
      max-width: 60%; } }
  .ac_image-gallery_grid_outer-column:nth-child(1) .ac_image-gallery_grid_column:nth-child(1) {
    height: 200px; }
    .ac_image-gallery_grid_outer-column:nth-child(1) .ac_image-gallery_grid_column:nth-child(1) .ac_image-gallery_grid_item {
      width: 50%;
      max-width: 50%; }
      @media screen and (min-width: 800px) {
        .ac_image-gallery_grid_outer-column:nth-child(1) .ac_image-gallery_grid_column:nth-child(1) .ac_image-gallery_grid_item {
          max-height: 50%; } }
    @media screen and (min-width: 800px) {
      .ac_image-gallery_grid_outer-column:nth-child(1) .ac_image-gallery_grid_column:nth-child(1) {
        height: 400px;
        width: 33.33333%;
        max-width: 33.33333%; }
        .ac_image-gallery_grid_outer-column:nth-child(1) .ac_image-gallery_grid_column:nth-child(1) .ac_image-gallery_grid_item {
          width: 100%;
          max-width: 100%;
          height: 180px; } }
  @media screen and (max-width: 799px) {
    .ac_image-gallery_grid_outer-column:nth-child(1) .ac_image-gallery_grid_column:nth-child(2) {
      height: 280px; } }
  @media screen and (min-width: 800px) {
    .ac_image-gallery_grid_outer-column:nth-child(1) .ac_image-gallery_grid_column:nth-child(2) {
      width: 66.66667%;
      max-width: 66.66667%; } }
  @media screen and (min-width: 800px) {
    .ac_image-gallery_grid_outer-column:nth-child(2) {
      width: 40%;
      max-width: 40%; } }
  .ac_image-gallery_grid_outer-column:nth-child(2) .ac_image-gallery_grid_item {
    width: 50%;
    max-width: 50%; }

.ac_image-gallery_grid_column {
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.ac_image-gallery_grid_item {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 20px;
  padding: 0 10px; }
  @media print, screen and (min-width: 64.0625em) {
    .ac_image-gallery_grid_item:hover .ac_image-gallery_grid_item_image {
      transform: scale(1.02);
      opacity: 0.5; }
    .ac_image-gallery_grid_item:hover .ac_image-gallery_grid_item_content {
      opacity: 1;
      visibility: visible; } }

.ac_image-gallery_grid_item_container {
  height: 100%;
  position: relative;
  flex-grow: 1;
  background: #353535;
  border-radius: 3px;
  overflow: hidden; }

.ac_image-gallery_grid_item_image-container {
  height: 160px;
  width: 100%;
  border-radius: 3px;
  position: relative;
  overflow: hidden; }

.ac_image-gallery_grid_item_image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  border-radius: 3px;
  transition: all 0.3s ease;
  will-change: contents; }

.ac_image-gallery_grid_item_content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 20px; }

.ac_image-gallery_grid_item_image-container {
  border-radius: 3px;
  height: 100%;
  position: absolute; }

.ac_image-gallery_grid_item_content {
  position: absolute;
  z-index: 2;
  top: 0;
  border: none;
  height: 100%;
  width: 100%;
  display: flex;
  opacity: 0;
  visibility: hidden;
  left: 0;
  color: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }

/*--------------------------------------------------------------------------------------------*/
.ac_image-gallery_grid_container {
  position: relative;
  z-index: 22; }

@media print, screen and (min-width: 50em) {
  .ac_image-gallery_grid_outer-column:nth-child(2) {
    padding-top: 100px; }
  .ac_image-gallery_grid_container:nth-child(even) .ac_image-gallery_grid_outer-column:nth-child(2) {
    padding-top: initial;
    margin-top: -100px; } }

/* AC_S_IMAGE-GALLERY-SLIDER - 1
--------------------------------------------------------------------------------------------*/
/* AC_S_IMAGE-GALLERY - GLOBALS
--------------------------------------------------------------------------------------------*/
.ac_image-gallery_container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto; }

.ac_image-gallery_item {
  padding: 0 10px;
  margin-bottom: 20px;
  flex: 0 1 50%;
  max-width: 50%; }

a.ac_image-gallery_item:hover .ac_image-gallery_item_container {
  background: rgba(53, 53, 53, 0.1); }

a.ac_image-gallery_item:hover .ac_image-gallery_item_image {
  opacity: 0.5; }

a.ac_image-gallery_item:hover .ac_image-gallery_item_content_inner {
  color: #353535; }

.ac_image-gallery_item_container {
  position: relative;
  background: #ffffff;
  height: 100%;
  transition: all 0.3s ease;
  border-radius: 6px; }

.ac_image-gallery_item_image_container {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  background: #353535;
  border-radius: 6px; }

.ac_image-gallery_item_image {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.3s ease;
  border-radius: 6px;
  background-size: cover; }

.ac_image-gallery_item_content {
  position: relative;
  padding: 20px;
  border-top: solid 1px rgba(102, 102, 102, 0.2);
  display: none; }

.ac_image-gallery_item_content_inner {
  transition: opacity 0.3s ease;
  font-size: 90%;
  color: #353535; }
  @media print, screen and (min-width: 50em) {
    .ac_image-gallery_item_content_inner {
      font-size: 100%; } }

/*--------------------------------------------------------------------------------------------*/
/* CTA
--------------------------------------------------------------------------------------------*/
[data-s-type="maps"] {
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="maps"] .ac_maps_container {
    width: 100%;
    height: 400px; }

/* AC_S_NEWS - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="news"] {
  /* ITEM  GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="news"] .ac_container {
    max-width: 1000px;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px; }
  [data-s-type="news"] .ac_item {
    display: flex;
    position: relative;
    margin-bottom: 1.4em;
    padding: 0 20px;
    padding: 0 10px; }
    @media screen and (max-width: 39.9375em) {
      [data-s-type="news"] .ac_item {
        width: 100%;
        max-width: 100%; } }
  [data-s-type="news"] .ac_item_container {
    min-height: 100%;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
    border-bottom: solid 1px rgba(102, 102, 102, 0.2);
    background: none; }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="news"] a.ac_item:hover .ac_item_image {
      transform: scale(1.02);
      opacity: 0.6; }
    [data-s-type="news"] a.ac_item:hover .ac_item_image-container {
      background: #353535; }
    [data-s-type="news"] a.ac_item:hover .ac_item_content {
      background: none; }
    [data-s-type="news"] a.ac_item:hover .ac_item_content_title {
      color: rgba(53, 53, 53, 0.6); }
    [data-s-type="news"] a.ac_item:hover .ac_item_content_copy-above {
      color: rgba(53, 53, 53, 0.4); }
    [data-s-type="news"] a.ac_item:hover .ac_item_content_copy-below {
      color: rgba(53, 53, 53, 0.4); } }
  @media screen and (max-width: 39.9375em) {
    [data-s-type="news"] .ac_item {
      height: auto !important; }
      [data-s-type="news"] .ac_item .ac_item_container {
        display: block;
        overflow: inherit;
        min-height: inherit; }
      [data-s-type="news"] .ac_item .ac_item_image-container {
        width: 80px;
        height: 80px;
        position: absolute;
        left: 0;
        top: 0;
        flex-shrink: 0; }
      [data-s-type="news"] .ac_item .ac_item_content {
        position: relative;
        background: transparent;
        justify-content: flex-start;
        text-align: left;
        min-height: 100px; }
      [data-s-type="news"] .ac_item .ac_item_content_copy {
        padding: 0 0 0 100px;
        margin-bottom: 20px; }
      [data-s-type="news"] .ac_item .ac_item_content_title {
        color: #353535;
        flex: 1; }
      [data-s-type="news"] .ac_item .ac_item_content_copy-above {
        color: #353535;
        flex: 1; }
      [data-s-type="news"] .ac_item .ac_item_content_copy-below {
        color: #353535;
        flex: 1; }
      [data-s-type="news"] .ac_item [data-border-bottom] {
        border-bottom: solid 1px rgba(102, 102, 102, 0.2) !important; } }
  [data-s-type="news"] .ac_item_image-container {
    height: 360px;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    background: #353535;
    border-radius: 6px; }
  [data-s-type="news"] .ac_item_image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
    transition: all 0.3s ease;
    will-change: contents;
    border-radius: 6px; }
  [data-s-type="news"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 20px;
    bottom: 20px; }
    [data-s-type="news"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(53, 53, 53, 0.6);
      transition: fill 0.3s ease; }
  [data-s-type="news"] .ac_item_content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0;
    transition: all 0.3s ease;
    background: none; }
  [data-s-type="news"] .ac_item_content_copy {
    padding: 20px 3em 20px 0;
    width: 100%;
    transition: all 0.3s ease; }
    [data-s-type="news"] .ac_item_content_copy * {
      transition: all 0.3s ease; }
  [data-s-type="news"] .ac_item_content_icon_container {
    display: none; }
  [data-s-type="news"] .ac_item_content_title {
    color: #353535; }
  [data-s-type="news"] .ac_item_content_copy-above {
    color: rgba(53, 53, 53, 0.6); }
  [data-s-type="news"] .ac_item_content_copy-below {
    color: rgba(53, 53, 53, 0.6); }
  [data-s-type="news"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 20px;
    bottom: 20px; }
    [data-s-type="news"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(53, 53, 53, 0.6);
      transition: fill 0.3s ease; }
  [data-s-type="news"] .ac_item {
    height: 360px;
    flex-direction: column; }
    @media print, screen and (min-width: 40em) {
      [data-s-type="news"] .ac_item {
        height: 360px; } }
  [data-s-type="news"] .ac_item_image-container {
    height: 100%;
    background: #353535;
    position: absolute; }
    [data-s-type="news"] .ac_item_image-container:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(53, 53, 53, 0), #353535);
      z-index: 1;
      bottom: 0; }
  [data-s-type="news"] .ac_item_content {
    position: absolute;
    z-index: 2;
    top: 0;
    border: none;
    height: 100%;
    width: 100%;
    display: inline-flex;
    left: 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start; }
  [data-s-type="news"] .ac_item_container {
    overflow: hidden; }
  @media screen and (max-width: 39.9375em) {
    [data-s-type="news"] .ac_item_image-container:before {
      display: none; } }
  @media screen and (max-width: 39.9375em) {
    [data-s-type="news"] .ac_item_image {
      opacity: 1; } }
  [data-s-type="news"] .ac_item_content_copy {
    padding-left: 20px; }
    @media print, screen and (min-width: 40em) {
      [data-s-type="news"] .ac_item_content_copy {
        background: #ffffff; } }
  [data-s-type="news"] .ac_item_content_copy-above {
    text-transform: uppercase;
    font-size: 85%;
    letter-spacing: 1px; }
  [data-s-type="news"] .ac_item_content_title {
    padding: 10px 0;
    line-height: 1.2;
    font-family: "Catamaran", Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 120%; }
  [data-s-type="news"] .ac_item_content_copy-below {
    text-transform: uppercase;
    font-size: 85%;
    letter-spacing: 1px; }
  @media screen and (max-width: 39.9375em) {
    [data-s-type="news"] .ac_item_icon svg {
      fill: rgba(53, 53, 53, 0.5); } }

/* AC_S_SERVICES - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="products"] {
  /* ITEM  GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="products"] .ac_container {
    max-width: 1200px;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap; }
  [data-s-type="products"] .ac_item {
    display: flex;
    position: relative;
    margin-bottom: 1.4em;
    padding: 0 20px;
    padding: 0 20px;
    border-left: solid 1px rgba(102, 102, 102, 0.2); }
    @media screen and (max-width: 39.9375em) {
      [data-s-type="products"] .ac_item {
        width: 50%;
        max-width: 50%; } }
  [data-s-type="products"] .ac_item_container {
    min-height: 100%;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
    border-bottom: solid 1px rgba(102, 102, 102, 0.2);
    background: none; }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="products"] a.ac_item:hover .ac_item_image {
      transform: scale(1.02);
      opacity: 0.8; }
    [data-s-type="products"] a.ac_item:hover .ac_item_image-container {
      background: #ffffff; }
    [data-s-type="products"] a.ac_item:hover .ac_item_content {
      background: none; }
    [data-s-type="products"] a.ac_item:hover .ac_item_content_icon {
      background: rgba(53, 53, 53, 0.6); }
      [data-s-type="products"] a.ac_item:hover .ac_item_content_icon svg {
        fill: rgba(255, 255, 255, 0.4); }
    [data-s-type="products"] a.ac_item:hover .ac_item_content_title {
      color: rgba(53, 53, 53, 0.6); }
    [data-s-type="products"] a.ac_item:hover .ac_item_content_copy-above {
      color: rgba(53, 53, 53, 0.4); }
    [data-s-type="products"] a.ac_item:hover .ac_item_content_copy-below {
      color: rgba(53, 53, 53, 0.4); }
    [data-s-type="products"] a.ac_item:hover .ac_item_icon svg {
      fill: rgba(53, 53, 53, 0.4); } }
  [data-s-type="products"][data-s-amount="2"] [data-s-amount-item]:nth-child(2n+1) {
    border-left: transparent; }
  [data-s-type="products"][data-s-amount="2"] [data-s-amount-item]:nth-child(2n+1) {
    border-left: transparent; }
  @media screen and (max-width: 49.9375em) {
    [data-s-type="products"][data-s-amount="3"] [data-s-amount-item]:nth-child(2n+1) {
      border-left: transparent; } }
  @media print, screen and (min-width: 50em) {
    [data-s-type="products"][data-s-amount="3"] [data-s-amount-item]:nth-child(3n+1) {
      border-left: transparent; } }
  @media screen and (max-width: 64em) {
    [data-s-type="products"][data-s-amount="4"] [data-s-amount-item]:nth-child(2n+1) {
      border-left: transparent; } }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="products"][data-s-amount="4"] [data-s-amount-item]:nth-child(4n+1) {
      border-left: transparent; } }
  [data-s-type="products"][data-s-amount="2"] [data-s-amount-item]:nth-last-child(-n + 2) [data-border-bottom] {
    border-bottom-color: transparent; }
  @media screen and (max-width: 49.9375em) {
    [data-s-type="products"][data-s-amount="3"] [data-s-amount-item]:nth-last-child(-n + 2) [data-border-bottom] {
      border-bottom-color: transparent; } }
  @media print, screen and (min-width: 50em) {
    [data-s-type="products"][data-s-amount="3"] [data-s-amount-item]:nth-last-child(-n + 3) [data-border-bottom] {
      border-bottom-color: transparent; } }
  @media screen and (max-width: 64em) {
    [data-s-type="products"][data-s-amount="4"] [data-s-amount-item]:nth-last-child(-n + 2) [data-border-bottom] {
      border-bottom-color: transparent; } }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="products"][data-s-amount="4"] [data-s-amount-item]:nth-last-child(-n + 4) [data-border-bottom] {
      border-bottom-color: transparent; } }
  [data-s-type="products"] .ac_item_image-container {
    height: 20vw;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    background: #ffffff;
    border-radius: 6px; }
  [data-s-type="products"] .ac_item_image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
    will-change: contents;
    border-radius: 6px; }
  [data-s-type="products"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 0;
    bottom: 20px; }
    [data-s-type="products"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(53, 53, 53, 0.6);
      transition: fill 0.3s ease; }
  [data-s-type="products"] .ac_item_content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0;
    transition: all 0.3s ease;
    background: none; }
  [data-s-type="products"] .ac_item_content_copy {
    padding: 20px 0;
    width: 100%;
    transition: all 0.3s ease; }
    [data-s-type="products"] .ac_item_content_copy * {
      transition: all 0.3s ease; }
  [data-s-type="products"] .ac_item_content_icon_container {
    display: flex;
    justify-content: center; }
  [data-s-type="products"] .ac_item_content_icon {
    width: 80px;
    height: 80px;
    background: #353535;
    padding: 10px;
    margin: 0 0 20px 0;
    border-radius: 100%; }
    [data-s-type="products"] .ac_item_content_icon svg {
      width: 60px;
      height: 60px;
      fill: white; }
  [data-s-type="products"] .ac_item_content_title {
    color: #353535; }
  [data-s-type="products"] .ac_item_content_copy-above {
    color: rgba(53, 53, 53, 0.6); }
  [data-s-type="products"] .ac_item_content_copy-below {
    color: rgba(53, 53, 53, 0.6); }
  [data-s-type="products"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 0;
    bottom: 20px; }
    [data-s-type="products"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(53, 53, 53, 0.6);
      transition: fill 0.3s ease; }
  [data-s-type="products"] .ac_item_image-container {
    border: solid 1px rgba(102, 102, 102, 0.2); }
  [data-s-type="products"] .ac_item_content_price {
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-end; }
  [data-s-type="products"] .ac_item_content_price_before {
    text-decoration: line-through;
    opacity: .6; }
  [data-s-type="products"] .ac_item_content_price_current {
    font-size: 120%; }
  [data-s-type="products"] .ac_item_content_label {
    position: absolute;
    top: 12%;
    left: 0;
    z-index: 11;
    background: #a56000fa;
    color: #ffffff;
    padding: 6.66667px 10px;
    text-transform: uppercase;
    font-family: "Catamaran", Arial, sans-serif;
    font-weight: 600;
    font-style: normal; }

/* QUOTES - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="quotes"] {
  /* ITEM  GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="quotes"] .ac_container {
    max-width: 1200px;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap; }
  [data-s-type="quotes"] .ac_item {
    display: flex;
    position: relative;
    margin-bottom: 1.4em;
    padding: 0; }
    @media screen and (max-width: 39.9375em) {
      [data-s-type="quotes"] .ac_item {
        width: 100%;
        max-width: 100%; } }
  [data-s-type="quotes"] .ac_item_container {
    min-height: 100%;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
    background: none; }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="quotes"] a.ac_item:hover .ac_item_image {
      transform: scale(1.02);
      opacity: 0.8; }
    [data-s-type="quotes"] a.ac_item:hover .ac_item_content {
      background: none; }
    [data-s-type="quotes"] a.ac_item:hover .ac_item_content_title {
      color: rgba(53, 53, 53, 0.6); }
    [data-s-type="quotes"] a.ac_item:hover .ac_item_content_copy-above {
      color: rgba(53, 53, 53, 0.4); }
    [data-s-type="quotes"] a.ac_item:hover .ac_item_content_copy-below {
      color: rgba(53, 53, 53, 0.4); } }
  [data-s-type="quotes"] .ac_item_image-container {
    display: none; }
  [data-s-type="quotes"] .ac_item_image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
    will-change: contents;
    border-radius: 6px; }
  [data-s-type="quotes"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 0;
    bottom: 20px; }
    [data-s-type="quotes"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(53, 53, 53, 0.6);
      transition: fill 0.3s ease; }
  [data-s-type="quotes"] .ac_item_content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0;
    transition: all 0.3s ease;
    background: none; }
  [data-s-type="quotes"] .ac_item_content_copy {
    padding: 20px 0;
    width: 100%;
    transition: all 0.3s ease; }
    [data-s-type="quotes"] .ac_item_content_copy * {
      transition: all 0.3s ease; }
  [data-s-type="quotes"] .ac_item_content_icon_container {
    display: none; }
  [data-s-type="quotes"] .ac_item_content_title {
    color: #353535; }
  [data-s-type="quotes"] .ac_item_content_copy-above {
    color: rgba(53, 53, 53, 0.6); }
  [data-s-type="quotes"] .ac_item_content_copy-below {
    color: rgba(53, 53, 53, 0.6); }
  [data-s-type="quotes"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 0;
    bottom: 20px; }
    [data-s-type="quotes"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(53, 53, 53, 0.6);
      transition: fill 0.3s ease; }
  [data-s-type="quotes"] .ac_container {
    display: block;
    z-index: inherit;
    max-height: 200px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s ease; }
    [data-s-type="quotes"] .ac_container.slick-initialized {
      max-height: inherit;
      overflow: visible;
      opacity: 1; }
  [data-s-type="quotes"] [data-s-amount-item] {
    margin-bottom: 0; }
  @media screen and (max-width: 39.9375em) {
    [data-s-type="quotes"] [data-s-amount-item] {
      border-left-color: transparent; } }
  [data-s-type="quotes"] .ac_item {
    justify-content: center;
    padding: 1.5em 80px; }
  [data-s-type="quotes"] .ac_item_container {
    max-width: 600px; }
  @media screen and (max-width: 39.9375em) {
    [data-s-type="quotes"] .ac_item_content_copy {
      padding: 0; } }
  [data-s-type="quotes"] .ac_item_content_title_quote-left,
  [data-s-type="quotes"] .ac_item_content_title_quote-right {
    position: absolute; }
    [data-s-type="quotes"] .ac_item_content_title_quote-left svg,
    [data-s-type="quotes"] .ac_item_content_title_quote-right svg {
      width: 3em;
      height: 3em;
      fill: rgba(53, 53, 53, 0.2); }
  [data-s-type="quotes"] .ac_item_content_title_quote-left {
    top: -1.5em;
    left: -1.5em; }
  [data-s-type="quotes"] .ac_item_content_title_quote-right {
    bottom: -1.5em;
    right: -1.5em; }
  [data-s-type="quotes"] .ac_item_author {
    display: inline-flex;
    align-items: center;
    margin-top: 1em; }
  [data-s-type="quotes"] .ac_item_avatar_container {
    width: 4em;
    height: 4em;
    position: relative;
    border: 4px solid #ffffff;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    margin-right: 1em;
    flex-shrink: 0; }
  [data-s-type="quotes"] .ac_item_avatar_image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%; }
  [data-s-type="quotes"] .ac_item_content_copy-above {
    text-transform: uppercase;
    font-size: 85%;
    letter-spacing: 1px; }
  [data-s-type="quotes"] .ac_item_content_title {
    position: relative;
    padding: 10px 0;
    font-family: "Catamaran", Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 110%; }
    @media print, screen and (min-width: 50em) {
      [data-s-type="quotes"] .ac_item_content_title {
        font-size: 140%; } }
  [data-s-type="quotes"] .ac_item_content_copy-below {
    text-transform: uppercase;
    font-size: 85%;
    letter-spacing: 1px; }
  [data-s-type="quotes"] .slick-dots {
    bottom: 0;
    transform: translateX(-50%) translateY(100%); }

/* REFERENCES - 2
--------------------------------------------------------------------------------------------*/
[data-s-type="references"] {
  /* ITEM  GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="references"] .ac_container {
    max-width: 1000px;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px; }
  [data-s-type="references"] .ac_item {
    display: flex;
    position: relative;
    margin-bottom: 1.4em;
    padding: 0 20px;
    padding: 0 10px; }
    @media screen and (max-width: 39.9375em) {
      [data-s-type="references"] .ac_item {
        width: 100%;
        max-width: 100%; } }
  [data-s-type="references"] .ac_item_container {
    min-height: 100%;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
    background: none; }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="references"] a.ac_item:hover .ac_item_image {
      transform: scale(1.02);
      opacity: 0.4; }
    [data-s-type="references"] a.ac_item:hover .ac_item_image-container {
      background: #353535; }
    [data-s-type="references"] a.ac_item:hover .ac_item_content {
      background: none; }
    [data-s-type="references"] a.ac_item:hover .ac_item_content_icon {
      background: white; }
      [data-s-type="references"] a.ac_item:hover .ac_item_content_icon svg {
        fill: #353535; }
    [data-s-type="references"] a.ac_item:hover .ac_item_content_title {
      color: rgba(255, 255, 255, 0.6); }
    [data-s-type="references"] a.ac_item:hover .ac_item_content_copy-above {
      color: rgba(255, 255, 255, 0.4); }
    [data-s-type="references"] a.ac_item:hover .ac_item_icon svg {
      fill: white; } }
  [data-s-type="references"] .ac_item_image-container {
    height: 240px;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    background: #353535;
    border-radius: 6px; }
  [data-s-type="references"] .ac_item_image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    transition: all 0.3s ease;
    will-change: contents;
    border-radius: 6px; }
  [data-s-type="references"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 20px;
    bottom: 20px; }
    [data-s-type="references"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(255, 255, 255, 0.4);
      transition: fill 0.3s ease; }
  [data-s-type="references"] .ac_item_content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 20px;
    transition: all 0.3s ease;
    background: none; }
  [data-s-type="references"] .ac_item_content_copy {
    padding: 20px 0;
    width: 100%;
    transition: all 0.3s ease; }
    [data-s-type="references"] .ac_item_content_copy * {
      transition: all 0.3s ease; }
  [data-s-type="references"] .ac_item_content_icon_container {
    display: flex;
    justify-content: center; }
  [data-s-type="references"] .ac_item_content_icon {
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    margin: 0 0 20px 0;
    border-radius: 100%; }
    [data-s-type="references"] .ac_item_content_icon svg {
      width: 60px;
      height: 60px;
      fill: white; }
  [data-s-type="references"] .ac_item_content_title {
    color: #ffffff; }
  [data-s-type="references"] .ac_item_content_copy-above {
    color: rgba(255, 255, 255, 0.6); }
  [data-s-type="references"] .ac_item_content_copy-below {
    display: none; }
  [data-s-type="references"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 20px;
    bottom: 20px; }
    [data-s-type="references"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(255, 255, 255, 0.4);
      transition: fill 0.3s ease; }
  [data-s-type="references"] .ac_item {
    height: 240px;
    flex-direction: column; }
    @media print, screen and (min-width: 40em) {
      [data-s-type="references"] .ac_item {
        height: 240px; } }
  [data-s-type="references"] .ac_item_image-container {
    height: 100%;
    background: #353535;
    position: absolute; }
    [data-s-type="references"] .ac_item_image-container:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(53, 53, 53, 0), #353535);
      z-index: 1;
      bottom: 0; }
  [data-s-type="references"] .ac_item_content {
    position: absolute;
    z-index: 2;
    top: 0;
    border: none;
    height: 100%;
    width: 100%;
    display: inline-flex;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
  [data-s-type="references"] .ac_item_container {
    overflow: hidden; }
  [data-s-type="references"] .ac_item_image-container {
    border: solid 3px #ffffff; }
  [data-s-type="references"] .ac_item_image {
    border-radius: 0; }
  [data-s-type="references"] .ac_item_content_title {
    font-family: "Catamaran", Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 150%;
    line-height: 1; }

/* REFERENCES-GRID - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="references-grid"] {
  /* ITEM  GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="references-grid"] .ac_container {
    max-width: 1000px;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px; }
  [data-s-type="references-grid"] .ac_item {
    display: flex;
    position: relative;
    margin-bottom: 20px;
    padding: 0 20px;
    padding: 0 10px; }
    @media screen and (max-width: 39.9375em) {
      [data-s-type="references-grid"] .ac_item {
        width: 100%;
        max-width: 100%; } }
  [data-s-type="references-grid"] .ac_item_container {
    min-height: 100%;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
    background: none; }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="references-grid"] a.ac_item:hover .ac_item_image {
      transform: scale(1.02);
      opacity: 0.4; }
    [data-s-type="references-grid"] a.ac_item:hover .ac_item_image-container {
      background: #353535; }
    [data-s-type="references-grid"] a.ac_item:hover .ac_item_content {
      background: none; }
    [data-s-type="references-grid"] a.ac_item:hover .ac_item_content_icon {
      background: white; }
      [data-s-type="references-grid"] a.ac_item:hover .ac_item_content_icon svg {
        fill: #353535; }
    [data-s-type="references-grid"] a.ac_item:hover .ac_item_content_title {
      color: rgba(255, 255, 255, 0.6); }
    [data-s-type="references-grid"] a.ac_item:hover .ac_item_content_copy-above {
      color: rgba(255, 255, 255, 0.4); }
    [data-s-type="references-grid"] a.ac_item:hover .ac_item_icon svg {
      fill: white; } }
  [data-s-type="references-grid"] .ac_item_image-container {
    height: 240px;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    background: #353535;
    border-radius: 6px; }
  [data-s-type="references-grid"] .ac_item_image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.2;
    transition: all 0.3s ease;
    will-change: contents;
    border-radius: 6px; }
  [data-s-type="references-grid"] .ac_item_icon {
    display: none; }
  [data-s-type="references-grid"] .ac_item_content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0;
    transition: all 0.3s ease;
    background: none; }
  [data-s-type="references-grid"] .ac_item_content_copy {
    padding: 10px;
    width: 100%;
    transition: all 0.3s ease; }
    [data-s-type="references-grid"] .ac_item_content_copy * {
      transition: all 0.3s ease; }
  [data-s-type="references-grid"] .ac_item_content_icon_container {
    display: flex;
    justify-content: center; }
  [data-s-type="references-grid"] .ac_item_content_icon {
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    margin: 0 0 20px 0;
    border-radius: 100%; }
    [data-s-type="references-grid"] .ac_item_content_icon svg {
      width: 60px;
      height: 60px;
      fill: white; }
  [data-s-type="references-grid"] .ac_item_content_title {
    color: #ffffff; }
  [data-s-type="references-grid"] .ac_item_content_copy-above {
    color: rgba(255, 255, 255, 0.6); }
  [data-s-type="references-grid"] .ac_item_content_copy-below {
    display: none; }
  [data-s-type="references-grid"] .ac_item_icon {
    display: none; }
  [data-s-type="references-grid"] .ac_item {
    height: 240px;
    flex-direction: column; }
    @media print, screen and (min-width: 40em) {
      [data-s-type="references-grid"] .ac_item {
        height: 240px; } }
  [data-s-type="references-grid"] .ac_item_image-container {
    height: 100%;
    background: #353535;
    position: absolute; }
    [data-s-type="references-grid"] .ac_item_image-container:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(53, 53, 53, 0), #353535);
      z-index: 1;
      bottom: 0; }
  [data-s-type="references-grid"] .ac_item_content {
    position: absolute;
    z-index: 2;
    top: 0;
    border: none;
    height: 100%;
    width: 100%;
    display: inline-flex;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
  [data-s-type="references-grid"] .ac_item_container {
    overflow: hidden; }
  [data-s-type="references-grid"] .ac_grid_container {
    max-width: 1000px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px; }
    [data-s-type="references-grid"] .ac_grid_container .ac_item_image {
      display: none; }
  [data-s-type="references-grid"] .ac_grid_outer-column {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%; }
    @media screen and (min-width: 800px) {
      [data-s-type="references-grid"] .ac_grid_outer-column:nth-child(1) {
        width: 60%;
        max-width: 60%; } }
    @media screen and (max-width: 799px) {
      [data-s-type="references-grid"] .ac_grid_outer-column:nth-child(2) {
        display: none; } }
    [data-s-type="references-grid"] .ac_grid_outer-column:nth-child(2) .ac_item {
      height: 200px;
      margin-bottom: 20px; }
      @media screen and (min-width: 800px) {
        [data-s-type="references-grid"] .ac_grid_outer-column:nth-child(2) .ac_item {
          height: 180px;
          margin-bottom: inherit; } }
    @media screen and (min-width: 800px) {
      [data-s-type="references-grid"] .ac_grid_outer-column:nth-child(2) {
        width: 40%;
        max-width: 40%; } }
    [data-s-type="references-grid"] .ac_grid_outer-column:nth-child(2) .ac_item {
      width: 50%;
      max-width: 50%;
      margin-bottom: 20px; }
      [data-s-type="references-grid"] .ac_grid_outer-column:nth-child(2) .ac_item:nth-child(1) .ac_item_image, [data-s-type="references-grid"] .ac_grid_outer-column:nth-child(2) .ac_item:nth-child(4) .ac_item_image {
        display: block; }
  [data-s-type="references-grid"] .ac_grid_column {
    height: 400px;
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    [data-s-type="references-grid"] .ac_grid_column .ac_item {
      width: 100%;
      height: calc(100% - 20px); }
    [data-s-type="references-grid"] .ac_grid_column:nth-child(1) {
      height: 200px; }
      [data-s-type="references-grid"] .ac_grid_column:nth-child(1) .ac_item {
        width: 50%;
        max-width: 50%; }
        [data-s-type="references-grid"] .ac_grid_column:nth-child(1) .ac_item:nth-child(1) .ac_item_image {
          display: block; }
      @media screen and (min-width: 800px) {
        [data-s-type="references-grid"] .ac_grid_column:nth-child(1) {
          height: 400px;
          width: 33.33333%;
          max-width: 33.33333%; }
          [data-s-type="references-grid"] .ac_grid_column:nth-child(1) .ac_item {
            width: 100%;
            max-width: 100%;
            height: 180px; } }
    @media screen and (max-width: 799px) {
      [data-s-type="references-grid"] .ac_grid_column:nth-child(2) {
        height: 280px; } }
    @media screen and (min-width: 800px) {
      [data-s-type="references-grid"] .ac_grid_column:nth-child(2) {
        width: 66.66667%;
        max-width: 66.66667%; } }
    [data-s-type="references-grid"] .ac_grid_column:nth-child(2) .ac_item_image {
      display: block; }
  [data-s-type="references-grid"] .ac_item_content_title {
    font-family: "Catamaran", Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 120%;
    line-height: 1; }

/* SERVICES-GRID - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="services-grid"] {
  /* ITEM  GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="services-grid"] .ac_container {
    max-width: 1200px;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px; }
  [data-s-type="services-grid"] .ac_item {
    display: flex;
    position: relative;
    margin-bottom: 20px;
    padding: 0 20px;
    padding: 0 10px; }
    @media screen and (max-width: 39.9375em) {
      [data-s-type="services-grid"] .ac_item {
        width: 100%;
        max-width: 100%; } }
  [data-s-type="services-grid"] .ac_item_container {
    min-height: 100%;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
    background: none; }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="services-grid"] a.ac_item:hover .ac_item_image {
      transform: scale(1.02);
      opacity: 0.4; }
    [data-s-type="services-grid"] a.ac_item:hover .ac_item_image-container {
      background: #353535; }
    [data-s-type="services-grid"] a.ac_item:hover .ac_item_content {
      background: none; }
    [data-s-type="services-grid"] a.ac_item:hover .ac_item_content_icon {
      background: white; }
      [data-s-type="services-grid"] a.ac_item:hover .ac_item_content_icon svg {
        fill: #353535; }
    [data-s-type="services-grid"] a.ac_item:hover .ac_item_content_title {
      color: rgba(255, 255, 255, 0.6); }
    [data-s-type="services-grid"] a.ac_item:hover .ac_item_icon svg {
      fill: white; } }
  [data-s-type="services-grid"] .ac_item_image-container {
    height: 240px;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    background: #353535;
    border-radius: 6px; }
  [data-s-type="services-grid"] .ac_item_image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.2;
    transition: all 0.3s ease;
    will-change: contents;
    border-radius: 6px; }
  [data-s-type="services-grid"] .ac_item_icon {
    display: none; }
  [data-s-type="services-grid"] .ac_item_content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0;
    transition: all 0.3s ease;
    background: none; }
  [data-s-type="services-grid"] .ac_item_content_copy {
    padding: 10px;
    width: 100%;
    transition: all 0.3s ease; }
    [data-s-type="services-grid"] .ac_item_content_copy * {
      transition: all 0.3s ease; }
  [data-s-type="services-grid"] .ac_item_content_icon_container {
    display: flex;
    justify-content: center; }
  [data-s-type="services-grid"] .ac_item_content_icon {
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    margin: 0 0 20px 0;
    border-radius: 100%; }
    [data-s-type="services-grid"] .ac_item_content_icon svg {
      width: 60px;
      height: 60px;
      fill: white; }
  [data-s-type="services-grid"] .ac_item_content_title {
    color: #ffffff; }
  [data-s-type="services-grid"] .ac_item_content_copy-above {
    display: none; }
  [data-s-type="services-grid"] .ac_item_content_copy-below {
    display: none; }
  [data-s-type="services-grid"] .ac_item_icon {
    display: none; }
  [data-s-type="services-grid"] .ac_item {
    height: 240px;
    flex-direction: column; }
    @media print, screen and (min-width: 40em) {
      [data-s-type="services-grid"] .ac_item {
        height: 240px; } }
  [data-s-type="services-grid"] .ac_item_image-container {
    height: 100%;
    background: #353535;
    position: absolute; }
    [data-s-type="services-grid"] .ac_item_image-container:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(53, 53, 53, 0), #353535);
      z-index: 1;
      bottom: 0; }
  [data-s-type="services-grid"] .ac_item_content {
    position: absolute;
    z-index: 2;
    top: 0;
    border: none;
    height: 100%;
    width: 100%;
    display: inline-flex;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
  [data-s-type="services-grid"] .ac_item_container {
    overflow: hidden; }
  [data-s-type="services-grid"] .ac_grid_container {
    max-width: 1200px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px; }
  [data-s-type="services-grid"] .ac_grid_outer-column {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%; }
    @media screen and (min-width: 800px) {
      [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(1) {
        width: 60%;
        max-width: 60%; } }
    [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(2) .ac_item {
      height: 180px;
      margin-bottom: 20px; }
      @media screen and (min-width: 800px) {
        [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(2) .ac_item {
          height: 160px;
          margin-bottom: inherit; } }
    @media screen and (min-width: 800px) {
      [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(2) {
        width: 40%;
        max-width: 40%; } }
    [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(2) .ac_item {
      width: 50%;
      max-width: 50%;
      margin-bottom: 20px; }
  [data-s-type="services-grid"] .ac_grid_column {
    height: 360px;
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    [data-s-type="services-grid"] .ac_grid_column .ac_item {
      width: 100%;
      height: calc(100% - 20px); }
    [data-s-type="services-grid"] .ac_grid_column:nth-child(1) {
      height: 180px; }
      [data-s-type="services-grid"] .ac_grid_column:nth-child(1) .ac_item {
        width: 50%;
        max-width: 50%; }
      @media screen and (min-width: 800px) {
        [data-s-type="services-grid"] .ac_grid_column:nth-child(1) {
          height: 360px;
          width: 33.33333%;
          max-width: 33.33333%; }
          [data-s-type="services-grid"] .ac_grid_column:nth-child(1) .ac_item {
            width: 100%;
            max-width: 100%;
            height: 160px; } }
    @media screen and (max-width: 799px) {
      [data-s-type="services-grid"] .ac_grid_column:nth-child(2) {
        height: 252px; } }
    @media screen and (min-width: 800px) {
      [data-s-type="services-grid"] .ac_grid_column:nth-child(2) {
        width: 66.66667%;
        max-width: 66.66667%; } }
  [data-s-type="services-grid"] .ac_item_content_title {
    font-family: "Catamaran", Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 120%;
    line-height: 1; }
  [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(1) {
    flex-direction: column-reverse;
    margin-bottom: 20px; }
    @media screen and (min-width: 800px) {
      [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(1) {
        width: 50%;
        max-width: 50%; } }
    [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(1) .ac_grid_column {
      width: 100%;
      max-width: 100%; }
      @media screen and (min-width: 800px) {
        [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(1) .ac_grid_column:nth-child(1) {
          height: inherit; } }
      [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(1) .ac_grid_column:nth-child(1) .ac_item {
        width: 50%;
        max-width: 50%; }
      [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(1) .ac_grid_column:nth-child(1) .ac_item {
        height: 180px; }
  @media screen and (min-width: 800px) {
    [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(2) {
      width: 50%;
      max-width: 50%; } }
  [data-s-type="services-grid"] .ac_grid_outer-column:nth-child(2) .ac_item {
    height: 260px; }

/* SERVICES - 4
--------------------------------------------------------------------------------------------*/
[data-s-type="services"] {
  /* ITEM  GLOBALS
--------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------*/ }
  [data-s-type="services"] .ac_container {
    max-width: 1200px;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px; }
  [data-s-type="services"] .ac_item {
    display: flex;
    position: relative;
    margin-bottom: 1.4em;
    padding: 0 20px;
    padding: 0 10px; }
    @media screen and (max-width: 39.9375em) {
      [data-s-type="services"] .ac_item {
        width: 100%;
        max-width: 100%; } }
  [data-s-type="services"] .ac_item_container {
    min-height: 100%;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
    background: #ffffff;
    border-radius: 6px; }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="services"] a.ac_item:hover .ac_item_image {
      transform: scale(1.02);
      opacity: 0.8; }
    [data-s-type="services"] a.ac_item:hover .ac_item_content {
      background: none; }
    [data-s-type="services"] a.ac_item:hover .ac_item_content_icon {
      background: #a56000; }
      [data-s-type="services"] a.ac_item:hover .ac_item_content_icon svg {
        fill: white; }
    [data-s-type="services"] a.ac_item:hover .ac_item_content_title {
      color: rgba(53, 53, 53, 0.6); }
    [data-s-type="services"] a.ac_item:hover .ac_item_content_copy-below {
      color: rgba(53, 53, 53, 0.4); }
    [data-s-type="services"] a.ac_item:hover .ac_item_icon svg {
      fill: #353535; } }
  [data-s-type="services"] .ac_item_image-container {
    display: none; }
  [data-s-type="services"] .ac_item_image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
    will-change: contents;
    border-radius: 6px; }
  [data-s-type="services"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 20px;
    bottom: 20px; }
    [data-s-type="services"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(53, 53, 53, 0.3);
      transition: fill 0.3s ease; }
  [data-s-type="services"] .ac_item_content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 20px 20px 40px 20px;
    transition: all 0.3s ease;
    background: none; }
  [data-s-type="services"] .ac_item_content_copy {
    padding: 0;
    width: 100%;
    transition: all 0.3s ease; }
    [data-s-type="services"] .ac_item_content_copy * {
      transition: all 0.3s ease; }
  [data-s-type="services"] .ac_item_content_icon {
    width: 60px;
    height: 60px;
    background: #353535;
    padding: 10px;
    margin: 0 0 20px 0;
    border-radius: 100%; }
    [data-s-type="services"] .ac_item_content_icon svg {
      width: 40px;
      height: 40px;
      fill: white; }
  [data-s-type="services"] .ac_item_content_title {
    color: #353535; }
  [data-s-type="services"] .ac_item_content_copy-above {
    display: none; }
  [data-s-type="services"] .ac_item_content_copy-below {
    color: rgba(53, 53, 53, 0.6); }
  [data-s-type="services"] .ac_item_icon {
    position: absolute;
    z-index: 2;
    width: 1.6em;
    height: 1.6em;
    right: 20px;
    bottom: 20px; }
    [data-s-type="services"] .ac_item_icon svg {
      width: 1.6em;
      height: 1.6em;
      fill: rgba(53, 53, 53, 0.3);
      transition: fill 0.3s ease; }
  [data-s-type="services"] .ac_item_content_title {
    font-family: "Catamaran", Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 150%;
    line-height: 1;
    margin-bottom: 10px; }
  [data-s-type="services"] .ac_item_content {
    padding: 20px 20px 40px 100px; }
  [data-s-type="services"] .ac_item_content_icon_container {
    position: absolute;
    top: 20px;
    left: 20px; }

/* AC_S_TEAM - 1
--------------------------------------------------------------------------------------------*/
/* AC_S_TEAM - 01 - GLOBALS
--------------------------------------------------------------------------------------------*/
[data-s-type="team"] {
  position: relative;
  padding: 20px 0; }
  @media print, screen and (min-width: 50em) {
    [data-s-type="team"] {
      padding: 0 0 10px 0; } }

.ac_team_container {
  max-width: 800px;
  position: relative;
  z-index: 2;
  margin: 2em auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px; }

.ac_team_item {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.4em;
  text-align: center;
  display: flex;
  padding: 0 20px;
  padding: 0 10px; }
  @media print, screen and (min-width: 64.0625em) {
    .ac_team_item:hover .ac_team_item_logo {
      opacity: 1; } }

.ac_team_item_container {
  width: 100%;
  position: relative;
  background: #ffffff;
  padding: 20px 0;
  border-radius: 6px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); }

.ac_team_item_content {
  background: transparent;
  padding: 20px; }

.ac_team_item_profile-image_container {
  display: flex;
  justify-content: center; }

.ac_team_item_profile-image {
  width: 120px;
  height: 120px;
  background-size: cover;
  border: solid 6px #ffffff;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 100%; }

.ac_team_item_name {
  font-size: 110%;
  margin-bottom: .6em; }

.ac_team_item_job-description {
  text-transform: uppercase;
  font-size: 90%;
  margin-bottom: .6em; }

.ac_team_item_socials {
  display: flex;
  margin-top: .6em;
  justify-content: center; }
  .ac_team_item_socials svg {
    width: 30px;
    height: 30px;
    fill: #ffffff; }
  .ac_team_item_socials a {
    margin-right: .6em;
    background: #353535;
    width: 40px;
    height: 40px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease;
    border-radius: 100%; }
    .ac_team_item_socials a:last-child {
      margin-right: 0; }
    @media print, screen and (min-width: 64.0625em) {
      .ac_team_item_socials a:hover {
        background: #353535; }
        .ac_team_item_socials a:hover svg {
          fill: rgba(255, 255, 255, 0.5); } }

/*--------------------------------------------------------------------------------------------*/
/* AC_S_TOPTASKS_FIXED - 1
--------------------------------------------------------------------------------------------*/
/* AC_S_TOPTASKS - GLOBALS
--------------------------------------------------------------------------------------------*/
[data-s-type="toptasks-fixed"] {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3333;
  width: 100%;
  background: #ffffff;
  border: none; }
  @media screen and (max-width: 64em) {
    [data-s-type="toptasks-fixed"] {
      box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1); } }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-type="toptasks-fixed"] {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
      top: 25vh;
      right: 0;
      bottom: inherit;
      left: inherit;
      width: 400px;
      border-radius: 6px;
      transform: translateX(340px); } }

.ac_toptasks_fixed_container_inner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0; }
  @media print, screen and (min-width: 40em) {
    .ac_toptasks_fixed_container_inner {
      padding: 0; } }
  @media print, screen and (min-width: 64.0625em) {
    .ac_toptasks_fixed_container_inner {
      display: block; } }

.ac_toptasks_fixed_item_container {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 39.9375em) {
    .ac_toptasks_fixed_item_container {
      flex-direction: column; } }
  @media print, screen and (min-width: 64.0625em) {
    .ac_toptasks_fixed_item_container {
      justify-content: flex-start; } }

.ac_toptasks_fixed_item {
  display: block;
  background: #ffffff;
  border-radius: 6px;
  width: 398px;
  position: relative; }
  @media screen and (max-width: 64em) {
    .ac_toptasks_fixed_item:after {
      content: '';
      position: absolute;
      top: 10%;
      right: 0;
      background: #ffffff;
      width: 1px;
      height: 80%; }
    .ac_toptasks_fixed_item:last-child:after {
      display: none; } }
  @media print, screen and (min-width: 64.0625em) {
    .ac_toptasks_fixed_item {
      margin-bottom: 0;
      transition: transform 0.3s ease;
      will-change: contents; }
      .ac_toptasks_fixed_item:hover {
        background: #ffffff;
        transform: translateX(-200px); }
        .ac_toptasks_fixed_item:hover .ac_toptasks_fixed_item_icon svg {
          fill: #a56000fa; }
        .ac_toptasks_fixed_item:hover .ac_toptasks_fixed_item_content_title {
          color: #353535; }
        .ac_toptasks_fixed_item:hover .ac_toptasks_fixed_item_content_btn-container {
          color: #353535; } }

.ac_toptasks_fixed_item_content_title {
  font-size: 90%;
  color: #353535; }
  @media print, screen and (min-width: 64.0625em) {
    .ac_toptasks_fixed_item_content_title {
      display: none; } }
  @media screen and (max-width: 39.9375em) {
    .ac_toptasks_fixed_item_content_title {
      text-align: center; } }

.ac_toptasks_fixed_item_content_btn-container {
  white-space: nowrap;
  display: none;
  color: rgba(53, 53, 53, 0.5); }
  @media print, screen and (min-width: 64.0625em) {
    .ac_toptasks_fixed_item_content_btn-container {
      display: inline-block;
      width: 200px; } }

.ac_toptasks_fixed_item_icon-container {
  position: relative;
  z-index: 1; }

.ac_toptasks_fixed_item_icon {
  width: 21px;
  height: 21px;
  background: inherit;
  padding: 0;
  border-radius: 100%; }
  @media print, screen and (min-width: 40em) {
    .ac_toptasks_fixed_item_icon {
      width: 60px;
      height: 60px;
      padding: 15px; } }
  .ac_toptasks_fixed_item_icon svg {
    width: 21px;
    height: 21px;
    fill: #353535; }
    @media print, screen and (min-width: 40em) {
      .ac_toptasks_fixed_item_icon svg {
        width: 30px;
        height: 30px; } }

/* FILTER - 1
--------------------------------------------------------------------------------------------*/
[data-s-type="section-content"] a:not(.button) {
  text-decoration: underline; }

/* AC_S_TOPTASKS - 1
--------------------------------------------------------------------------------------------*/
/* AC_S_TOPTASKS - GLOBALS
--------------------------------------------------------------------------------------------*/
[data-s-type="toptasks"] {
  position: relative; }

.ac_toptasks_container {
  max-width: 1200px;
  margin: 2em auto;
  padding: 0; }

.ac_toptasks_container_inner {
  display: flex;
  flex-wrap: wrap;
  padding: 5px; }

.ac_toptasks_item {
  display: flex;
  padding: 10px;
  position: relative;
  width: 100%; }
  @media print, screen and (min-width: 64.0625em) {
    .ac_toptasks_item:hover .ac_toptasks_item_container {
      background: #353535; }
    .ac_toptasks_item:hover .ac_toptasks_item_image {
      opacity: 0.5; }
    .ac_toptasks_item:hover .ac_toptasks_item_icon {
      background: rgba(255, 255, 255, 0.1); }
      .ac_toptasks_item:hover .ac_toptasks_item_icon svg {
        fill: #ffffff; }
    .ac_toptasks_item:hover .ac_toptasks_item_content_title {
      color: #ffffff; } }

.ac_toptasks_item_container {
  background: #fafafd;
  width: 100%;
  border-radius: 6px;
  padding: 20px;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); }

.ac_toptasks_item_image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: #F28C00;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; }

.ac_toptasks_item_image {
  display: none; }

.ac_toptasks_item_content {
  position: relative;
  z-index: 1;
  flex: 1; }

.ac_toptasks_item_content_title {
  font-family: "Catamaran", Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 120%;
  margin-bottom: .4em;
  max-width: 100%;
  line-height: 1;
  color: #353535;
  transition: all 0.3s ease; }

.ac_toptasks_item_content_description {
  display: none; }

.ac_toptasks_item_content_btn-container {
  display: none; }

.ac_toptasks_item_icon-container {
  position: relative;
  z-index: 1;
  margin-bottom: 40px; }

.ac_toptasks_item_icon {
  width: 80px;
  height: 80px;
  background: #ffffff;
  padding: 12px;
  border-radius: 100%;
  transition: all 0.3s ease; }
  .ac_toptasks_item_icon svg {
    width: 56px;
    height: 56px;
    transition: all 0.3s ease;
    fill: #424242; }

/*--------------------------------------------------------------------------------------------*/
@media screen and (min-width: 40em) and (max-width: 49.9375em) {
  .ac_toptasks_item {
    width: 100%;
    max-width: 100%; } }

@media screen and (min-width: 40em) and (max-width: 49.9375em) {
  .ac_toptasks_item:after {
    top: inherit;
    right: inherit;
    left: 20px;
    bottom: 0;
    height: 1px;
    width: calc(100% - 40px); } }

.ac_toptasks_item_container {
  display: flex;
  overflow: hidden;
  align-items: center; }

.ac_toptasks_item_image-container {
  display: block;
  width: 120px;
  height: 100%;
  background: #F28C00;
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 0; }

.ac_toptasks_item_icon-container {
  flex: 0 0 120px;
  margin-bottom: 0; }

.ac_toptasks_item_content_title {
  margin-bottom: 0; }

/* VAC - 3
--------------------------------------------------------------------------------------------*/
/* AC_S_VAC - GLOBALS
--------------------------------------------------------------------------------------------*/
.ac_vac_container {
  max-width: 1200px;
  margin: 0 auto; }

.ac_vac_item_container {
  display: flex;
  margin-bottom: 0; }
  @media screen and (max-width: 39.9375em) {
    .ac_vac_item_container {
      flex-wrap: wrap;
      margin-bottom: 0; } }

.ac_vac_item_visual {
  position: relative;
  width: 100%;
  height: 250px; }
  @media print, screen and (min-width: 64.0625em) {
    .ac_vac_item_visual {
      width: 60%;
      max-width: 60%;
      min-height: 500px;
      height: inherit; } }

.ac_vac_item_content {
  width: 100%;
  max-width: 100%; }
  @media print, screen and (min-width: 64.0625em) {
    .ac_vac_item_content {
      width: 40%;
      max-width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center; } }

.ac_vac_item_content_inner {
  max-width: 500px;
  padding: 20px; }

@media print, screen and (min-width: 40em) {
  .ac_vac_item.v_text-right .ac_vac_item_container {
    flex-direction: row-reverse; } }

.ac_vac_container {
  display: block;
  z-index: inherit;
  max-height: 500px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease; }
  .ac_vac_container.slick-initialized {
    max-height: inherit;
    overflow: visible;
    opacity: 1; }

/*--------------------------------------------------------------------------------------------*/
.ac_vac_item_container {
  min-height: 500px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  display: flex; }

@media print, screen and (min-width: 40em) {
  .ac_vac_item.v_text-right .ac_vac_item_container {
    flex-direction: column;
    align-items: flex-end; } }

@media print, screen and (min-width: 64.0625em) {
  .ac_vac_item.v_text-right .ac_vac_item_content {
    padding-right: 60px; } }

@media print, screen and (min-width: 64.0625em) {
  .ac_vac_item.v_text-left .ac_vac_item_content {
    padding-left: 60px; } }

.ac_vac_item_visual {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.ac_vac_item_content {
  margin: 40px 0; }

.ac_vac_item_content_inner {
  width: 90%;
  min-width: initial;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(29, 29, 27, 0.2);
  z-index: 2;
  position: relative;
  padding: 40px; }
  @media print, screen and (min-width: 64.0625em) {
    .ac_vac_item_content_inner {
      width: initial;
      margin: initial; } }

/* SECTION CONTROL
--------------------------------------------------------------------------------------------*/
[data-s-id="home-toptasks"] {
  margin-top: -60px;
  position: relative;
  z-index: 22; }
  @media print, screen and (min-width: 64.0625em) {
    [data-s-id="home-toptasks"] {
      margin-top: -110px; } }

[data-s-id="home-services"] {
  padding-top: 40px;
  padding-bottom: 120px; }

/* STATE HIDDEN
----------------------------------------------------------------------------------------------------*/
.s_hidden {
  display: none !important; }

/* TEXT ALIGN
----------------------------------------------------------------------------------------------------*/
.a_text-align_left {
  text-align: left !important; }

.a_text-align_center {
  text-align: center !important; }

.a_text-align_right {
  text-align: right !important; }

/* FLEXBOX HELPERS
----------------------------------------------------------------------------------------------------*/
.a_justify_flex-start {
  justify-content: flex-start !important; }

.a_justify_center {
  justify-content: center !important; }

.a_justify_flex-end {
  justify-content: flex-end !important; }

.a_justify_space-around {
  justify-content: space-around !important; }

.a_justify_space-between {
  justify-content: space-between !important; }

.a_align-items_center {
  align-items: center !important; }

.a_align-items_flex-start {
  align-items: flex-start !important; }

.a_align-items_flex-end {
  align-items: flex-end !important; }

.a_flex-direction_row {
  flex-direction: row !important; }

.a_flex-direction_row-reverse {
  flex-direction: row-reverse !important; }

/* MAX WIDTH
----------------------------------------------------------------------------------------------------*/
.a_max-width-full {
  max-width: 100% !important; }

.a_max-width-600 {
  max-width: 600px !important;
  margin: 0 auto; }

.a_max-width-800 {
  max-width: 800px !important;
  margin: 0 auto; }

.a_max-width-1000 {
  max-width: 1000px !important;
  margin: 0 auto; }

.a_max-width-1200 {
  max-width: 1200px !important;
  margin: 0 auto; }

.a_max-width-1400 {
  max-width: 1400px !important;
  margin: 0 auto; }

.a_max-width-1600 {
  max-width: 1600px !important;
  margin: 0 auto; }

.a_max-width-1800 {
  max-width: 1800px !important;
  margin: 0 auto; }

.a_max-width-2000 {
  max-width: 2000px !important;
  margin: 0 auto; }

/* BACKGROUND ATTACHMENT 
----------------------------------------------------------------------------------------------------*/
@media print, screen and (min-width: 64.0625em) {
  .a_background-attachment_fixed {
    background-attachment: fixed; } }

/* BACKGROUND CONTAIN
----------------------------------------------------------------------------------------------------*/
.a_contain {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center; }

/* BACKGROUND COVER
----------------------------------------------------------------------------------------------------*/
.a_cover-ct {
  background-size: cover !important;
  background-position: 50% 0% !important; }

.a_cover-cc {
  background-size: cover !important;
  background-position: 50% 50% !important; }

.a_cover-cb {
  background-size: cover !important;
  background-position: 50% 100% !important; }

.a_cover-lt {
  background-size: cover !important;
  background-position: 0% 0% !important; }

.a_cover-lc {
  background-size: cover !important;
  background-position: 0% 50% !important; }

.a_cover-lb {
  background-size: cover !important;
  background-position: 0% 100% !important; }

.a_cover-rt {
  background-size: cover !important;
  background-position: 100% 0% !important; }

.a_cover-rc {
  background-size: cover !important;
  background-position: 100% 50% !important; }

.a_cover-rb {
  background-size: cover !important;
  background-position: 100% 100% !important; }

@media print, screen and (min-width: 40em) {
  .a_medium-portrait_cover-ct {
    background-position: 50% 0% !important; } }

@media print, screen and (min-width: 40em) {
  .a_medium-portrait_cover-cc {
    background-position: 50% 50% !important; } }

@media print, screen and (min-width: 40em) {
  .a_medium-portrait_cover-cb {
    background-position: 50% 100% !important; } }

@media print, screen and (min-width: 40em) {
  .a_medium-portrait_cover-lt {
    background-position: 0% 0% !important; } }

@media print, screen and (min-width: 40em) {
  .a_medium-portrait_cover-lc {
    background-position: 0% 50% !important; } }

@media print, screen and (min-width: 40em) {
  .a_medium-portrait_cover-lb {
    background-position: 0% 100% !important; } }

@media print, screen and (min-width: 40em) {
  .a_medium-portrait_cover-rt {
    background-position: 100% 0% !important; } }

@media print, screen and (min-width: 40em) {
  .a_medium-portrait_cover-rc {
    background-position: 100% 50% !important; } }

@media print, screen and (min-width: 40em) {
  .a_medium-portrait_cover-rb {
    background-position: 100% 100% !important; } }

@media print, screen and (min-width: 50em) {
  .a_medium_cover-ct {
    background-position: 50% 0% !important; } }

@media print, screen and (min-width: 50em) {
  .a_medium_cover-cc {
    background-position: 50% 50% !important; } }

@media print, screen and (min-width: 50em) {
  .a_medium_cover-cb {
    background-position: 50% 100% !important; } }

@media print, screen and (min-width: 50em) {
  .a_medium_cover-lt {
    background-position: 0% 0% !important; } }

@media print, screen and (min-width: 50em) {
  .a_medium_cover-lc {
    background-position: 0% 50% !important; } }

@media print, screen and (min-width: 50em) {
  .a_medium_cover-lb {
    background-position: 0% 100% !important; } }

@media print, screen and (min-width: 50em) {
  .a_medium_cover-rt {
    background-position: 100% 0% !important; } }

@media print, screen and (min-width: 50em) {
  .a_medium_cover-rc {
    background-position: 100% 50% !important; } }

@media print, screen and (min-width: 50em) {
  .a_medium_cover-rb {
    background-position: 100% 100% !important; } }

/* OPACITY
----------------------------------------------------------------------------------------------------*/
.a_opacity-0 {
  opacity: 0 !important; }

.a_opacity-5 {
  opacity: 0.05 !important; }

.a_opacity-10 {
  opacity: 0.1 !important; }

.a_opacity-20 {
  opacity: 0.2 !important; }

.a_opacity-30 {
  opacity: 0.3 !important; }

.a_opacity-40 {
  opacity: 0.4 !important; }

.a_opacity-50 {
  opacity: 0.5 !important; }

.a_opacity-60 {
  opacity: 0.6 !important; }

.a_opacity-70 {
  opacity: 0.7 !important; }

.a_opacity-80 {
  opacity: 0.8 !important; }

.a_opacity-90 {
  opacity: 0.9 !important; }

.a_opacity-100 {
  opacity: 1 !important; }

/* GRAYSCALE
----------------------------------------------------------------------------------------------------*/
.a_grayscale-20 {
  filter: grayscale(20%) !important; }

.a_grayscale-40 {
  filter: grayscale(40%) !important; }

.a_grayscale-60 {
  filter: grayscale(60%) !important; }

.a_grayscale-80 {
  filter: grayscale(80%) !important; }

.a_grayscale-100 {
  filter: grayscale(100%) !important; }

/* BLEND MODES
----------------------------------------------------------------------------------------------------*/
.a_blend-multiply {
  mix-blend-mode: multiply !important; }

.a_blend-screen {
  mix-blend-mode: screen !important; }

.a_blend-overlay {
  mix-blend-mode: overlay !important; }

.a_blend-exclusion {
  mix-blend-mode: exclusion !important; }

.a_blend-difference {
  mix-blend-mode: difference !important; }

/* SHADOWS
----------------------------------------------------------------------------------------------------*/
.a_cast-shadow_top {
  box-shadow: 0px -10px 10px rgba(34, 34, 34, 0.1) !important; }

/* MARGIN HELPERS
----------------------------------------------------------------------------------------------------*/
.a_no-margin {
  margin: 0 !important; }

.a_no-margin-top {
  margin-top: 0 !important; }

.a_no-margin-right {
  margin-right: 0 !important; }

.a_no-margin-bottom {
  margin-bottom: 0 !important; }

.a_no-margin-left {
  margin-left: 0 !important; }

.a_margin-top-0 {
  margin-top: 0px !important; }

.a_margin-bottom-0 {
  margin-bottom: 0px !important; }

.a_margin-top-10 {
  margin-top: 5px !important; }

.a_margin-bottom-10 {
  margin-bottom: 5px !important; }

.a_margin-top-20 {
  margin-top: 10px !important; }

.a_margin-bottom-20 {
  margin-bottom: 10px !important; }

.a_margin-top-40 {
  margin-top: 20px !important; }

.a_margin-bottom-40 {
  margin-bottom: 20px !important; }

.a_margin-top-60 {
  margin-top: 30px !important; }

.a_margin-bottom-60 {
  margin-bottom: 30px !important; }

.a_margin-top-80 {
  margin-top: 40px !important; }

.a_margin-bottom-80 {
  margin-bottom: 40px !important; }

.a_margin-top-100 {
  margin-top: 50px !important; }

.a_margin-bottom-100 {
  margin-bottom: 50px !important; }

@media print, screen and (min-width: 50em) {
  .a_margin-top-0 {
    margin-top: 0px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-bottom-0 {
    margin-bottom: 0px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-top-10 {
    margin-top: 10px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-bottom-10 {
    margin-bottom: 10px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-top-20 {
    margin-top: 20px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-bottom-20 {
    margin-bottom: 20px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-top-40 {
    margin-top: 40px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-bottom-40 {
    margin-bottom: 40px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-top-60 {
    margin-top: 60px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-bottom-60 {
    margin-bottom: 60px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-top-80 {
    margin-top: 80px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-bottom-80 {
    margin-bottom: 80px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-top-100 {
    margin-top: 100px !important; } }

@media print, screen and (min-width: 50em) {
  .a_margin-bottom-100 {
    margin-bottom: 100px !important; } }

/* PADDING HELPERS
----------------------------------------------------------------------------------------------------*/
.a_no-padding {
  padding: 0 !important; }

.a_no-padding-top {
  padding-top: 0 !important; }

.a_no-padding-right {
  padding-right: 0 !important; }

.a_no-padding-bottom {
  padding-bottom: 0 !important; }

.a_no-padding-left {
  padding-left: 0 !important; }

.a_padding-top-0 {
  padding-top: 0px !important; }

.a_padding-bottom-0 {
  padding-bottom: 0px !important; }

@media print, screen and (min-width: 50em) {
  .a_padding-top-0 {
    padding-top: 0px !important; } }

@media print, screen and (min-width: 50em) {
  .a_padding-bottom-0 {
    padding-bottom: 0px !important; } }

.a_padding-top-10 {
  padding-top: 5px !important; }

.a_padding-bottom-10 {
  padding-bottom: 5px !important; }

@media print, screen and (min-width: 50em) {
  .a_padding-top-10 {
    padding-top: 10px !important; } }

@media print, screen and (min-width: 50em) {
  .a_padding-bottom-10 {
    padding-bottom: 10px !important; } }

.a_padding-top-20 {
  padding-top: 10px !important; }

.a_padding-bottom-20 {
  padding-bottom: 10px !important; }

@media print, screen and (min-width: 50em) {
  .a_padding-top-20 {
    padding-top: 20px !important; } }

@media print, screen and (min-width: 50em) {
  .a_padding-bottom-20 {
    padding-bottom: 20px !important; } }

.a_padding-top-40 {
  padding-top: 20px !important; }

.a_padding-bottom-40 {
  padding-bottom: 20px !important; }

@media print, screen and (min-width: 50em) {
  .a_padding-top-40 {
    padding-top: 40px !important; } }

@media print, screen and (min-width: 50em) {
  .a_padding-bottom-40 {
    padding-bottom: 40px !important; } }

.a_padding-top-60 {
  padding-top: 30px !important; }

.a_padding-bottom-60 {
  padding-bottom: 30px !important; }

@media print, screen and (min-width: 50em) {
  .a_padding-top-60 {
    padding-top: 60px !important; } }

@media print, screen and (min-width: 50em) {
  .a_padding-bottom-60 {
    padding-bottom: 60px !important; } }

.a_padding-top-80 {
  padding-top: 40px !important; }

.a_padding-bottom-80 {
  padding-bottom: 40px !important; }

@media print, screen and (min-width: 50em) {
  .a_padding-top-80 {
    padding-top: 80px !important; } }

@media print, screen and (min-width: 50em) {
  .a_padding-bottom-80 {
    padding-bottom: 80px !important; } }

.a_padding-top-100 {
  padding-top: 50px !important; }

.a_padding-bottom-100 {
  padding-bottom: 50px !important; }

@media print, screen and (min-width: 50em) {
  .a_padding-top-100 {
    padding-top: 100px !important; } }

@media print, screen and (min-width: 50em) {
  .a_padding-bottom-100 {
    padding-bottom: 100px !important; } }

.a_padding-top-120 {
  padding-top: 60px !important; }

.a_padding-bottom-120 {
  padding-bottom: 60px !important; }

@media print, screen and (min-width: 50em) {
  .a_padding-top-120 {
    padding-top: 120px !important; } }

@media print, screen and (min-width: 50em) {
  .a_padding-bottom-120 {
    padding-bottom: 120px !important; } }

.a_padding-top-140 {
  padding-top: 70px !important; }

.a_padding-bottom-140 {
  padding-bottom: 70px !important; }

@media print, screen and (min-width: 50em) {
  .a_padding-top-140 {
    padding-top: 140px !important; } }

@media print, screen and (min-width: 50em) {
  .a_padding-bottom-140 {
    padding-bottom: 140px !important; } }

/* BACKGROUND COLOR
----------------------------------------------------------------------------------------------------*/
.a_bg_brand_primary {
  background-color: #F28C00 !important; }

.a_bg_brand_primary-darker {
  background-color: #a56000fa !important; }

.a_bg_brand_secondary {
  background-color: #1d1d1b !important; }

.a_bg_brand_secondary-darker {
  background-color: #10100f !important; }

.a_bg_brand_tertiary {
  background-color: #424242 !important; }

.a_bg_brand_tertiary-darker {
  background-color: #353535 !important; }

.a_bg_brand_black {
  background-color: #1D1D1B !important; }

.a_bg_brand_white {
  background-color: #ffffff !important; }

.a_bg_brand_light-gray {
  background-color: #f0f0f0 !important; }

.a_bg_brand_lightest-gray {
  background-color: #fafafd !important; }

.a_bg_default_light {
  background: #ffffff !important; }

.a_bg_default_dark {
  background: #353535 !important; }

/* BACKGROUND GRADIENTS
----------------------------------------------------------------------------------------------------*/
.a_bg_gradient_brand_primary {
  background: linear-gradient(-135deg, #a56000fa, #F28C00) !important; }

.a_bg_gradient_brand_secondary {
  background: linear-gradient(-135deg, #10100f, #1d1d1b) !important; }

.a_bg_gradient_brand_tertiary {
  background: linear-gradient(-135deg, #353535, #424242) !important; }

/* FILL COLOR
----------------------------------------------------------------------------------------------------*/
.a_fill_brand_primary svg {
  fill: #F28C00 !important; }

.a_fill_brand_primary-darker svg {
  fill: #a56000fa !important; }

.a_fill_brand_secondary svg {
  fill: #1d1d1b !important; }

.a_fill_brand_secondary-darker svg {
  fill: #10100f !important; }

.a_fill_brand_tertiary svg {
  fill: #424242 !important; }

.a_fill_brand_tertiary-darker svg {
  fill: #353535 !important; }

.a_fill_brand_black svg {
  fill: #1D1D1B !important; }

.a_fill_brand_white svg {
  fill: #ffffff !important; }

.a_fill_brand_light-gray svg {
  fill: #f0f0f0 !important; }

.a_fill_brand_lightest-gray svg {
  fill: #fafafd !important; }

.a_fill_default svg {
  fill: #353535 !important; }

.a_fill_default_light svg {
  fill: #ffffff !important; }

.a_fill_default_dark svg {
  fill: #353535 !important; }

/* BORDERS
----------------------------------------------------------------------------------------------------*/
.a_border-top_brand_light-gray {
  border-top: solid 1px #f0f0f0 !important; }

.a_border-bottom_brand_light-gray {
  border-bottom: solid 1px #f0f0f0 !important; }

/* TEXT COLOR
----------------------------------------------------------------------------------------------------*/
.a_text_brand_primary * {
  color: #F28C00 !important; }

.a_text_brand_primary-darker * {
  color: #a56000fa !important; }

.a_text_brand_secondary * {
  color: #1d1d1b !important; }

.a_text_brand_secondary-darker * {
  color: #10100f !important; }

.a_text_brand_tertiary * {
  color: #424242 !important; }

.a_text_brand_tertiary-darker * {
  color: #353535 !important; }

.a_text_brand_black * {
  color: #1D1D1B !important; }

.a_text_brand_white * {
  color: #ffffff !important; }

.a_text_brand_light-gray * {
  color: #f0f0f0 !important; }

.a_text_brand_lightest-gray * {
  color: #fafafd !important; }

.a_text_default * {
  color: #353535 !important; }

.a_text_default_light * {
  color: #ffffff !important; }

.a_text_default_dark * {
  color: #353535 !important; }

/* FIXES
*/
.is-dropdown-submenu-item a {
  color: #1d1d1b; }

.ac_menu-2_bar_top .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  border-color: #ffffff transparent; }

.ac_socials_link_icon {
  background: #F28C00 !important; }

.ac_menu-2_bar_top {
  background: #000; }

.ac_hero_slide_content_text_title .column-title {
  font-size: 50px;
  text-shadow: 1px 2px #2d2d2d; }

.filter-items .button {
  background: #1d1d1b !important; }

@media only screen and (max-width: 639px) {
  #home-news-5 .ac_item .ac_item_content_copy-above {
    color: #fff; }
  #home-news-5 .ac_item .ac_item_content_title {
    color: #fff; }
  [data-s-type="hero"] .ac_hero_slide_content_image {
    bottom: auto; } }

[data-f-type="footer-1"] .ac_footer_primary {
  background: #1d1d1b; }

@media print, screen and (min-width: 50em) {
  [data-f-type="footer-1"] .ac_footer_primary_container {
    padding: 20px 10px 0 10px; }
  [data-f-type="footer-1"] .ac_footer_primary_column {
    height: auto; }
    [data-f-type="footer-1"] .ac_footer_primary_column h2 {
      margin: 10px 0 5px 0; }
    [data-f-type="footer-1"] .ac_footer_primary_column .ac_footer-1_editor {
      margin-bottom: 0; }
    [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(1), [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(2) {
      flex: 0 1 50%;
      max-width: 50%; }
    [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(3) {
      padding-top: 10px;
      flex: 0 1 100%;
      max-width: 100%;
      opacity: .5; } }

.ac_footer-1_content img {
  float: right;
  margin-top: -40px; }

@media print, screen and (max-width: 815px) {
  .ac_footer-1_content img {
    float: none;
    margin-top: 0px; }
  .ac_footer_primary_column {
    display: block !important; }
  [data-f-type="footer-1"] {
    padding-bottom: 0px; } }

input[type="radio" i] {
  padding: 0 !important;
  margin: 0 !important; }

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 10px !important;
  display: inline; }

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
  margin-top: -26px !important; }

.ac_content_1-1 {
  padding-left: 10px; }

/*
.ac_image-gallery_item_image_container {
    height: 500px;
}

.ac_image-gallery_container:before {
	display:none;
}

.ac_image-gallery_container:after {
	display:none;
}*/
