/* FIXES
*/

.is-dropdown-submenu-item a {
    color:#1d1d1b;
}

.ac_menu-2_bar_top .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    border-color: #ffffff transparent;
}

.ac_socials_link_icon {
    background: $brand-primary !important;
}

.ac_menu-2_bar_top {
    background: #000;
}

.ac_hero_slide_content_text_title .column-title{
    font-size: 50px;
    text-shadow: 1px 2px #2d2d2d;
    //max-width: 300px;
}

.filter-items .button {
    background: $brand-secondary !important;
}
@media only screen and (max-width: 639px) {
     
        #home-news-5 .ac_item .ac_item_content_copy-above {
            color:#fff;
        }
        #home-news-5 .ac_item .ac_item_content_title {
            color:#fff;
        }

        [data-s-type="hero"] .ac_hero_slide_content_image {
            bottom:auto;
        }

        #home-hero-1 .ac_backdrop {
            //display:none;
        }
    
}

[data-f-type="footer-1"] .ac_footer_primary {
    background: $brand-secondary;
}


@media print, screen and (min-width: 50em){
    [data-f-type="footer-1"]{

        .ac_footer_primary_container{
            padding: 20px 10px 0 10px;
        }

        .ac_footer_primary_column{
            height: auto;

            h2{
                margin: 10px 0 5px 0;
            }

            .ac_footer-1_editor{
                margin-bottom: 0;
            }

            &:nth-child(1), &:nth-child(2){
                flex: 0 1 50%;
                max-width: 50%;
            }
            &:nth-child(3){
                padding-top: 10px;
                flex: 0 1 100%;
                max-width: 100%;
                opacity:.5;
            }
        }
    }
}

.ac_footer-1_content img {
    float:right;
    margin-top:-40px;
}
@media print, screen and (max-width: 815px){
    .ac_footer-1_content img {
        float:none;
        margin-top:0px;
    }

    .ac_footer_primary_column{
        display: block !important;
    }

    [data-f-type="footer-1"] {
        padding-bottom:0px;
    }
}

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}

.ac_content_1-1 {
    padding-left:10px;
}

/*
.ac_image-gallery_item_image_container {
    height: 500px;
}

.ac_image-gallery_container:before {
	display:none;
}

.ac_image-gallery_container:after {
	display:none;
}*/