
/* HERO - 2
--------------------------------------------------------------------------------------------*/

[data-s-type="hero"] {

    // hero
    $hero-height: 30vh; // 100vh, 80vh, 400px, etc
    $hero-min-height: 500px; // optional and handy for smaller sizes, to prevent collapsing of content
    $hero-gradient-before: true;
    $hero-gradient-before-height: 25vh;
    $hero-gradient-before-position: 'top';
    $hero-gradient-color: darken($default-background-dark, 2%);

    // slide
    $hero-slide-max-width: 800px;
    $hero-slide-content-correction: -3em; // -3em // allows for visual correction of outlining content, when using a divider
    $hero-slide-type: 'text-image'; // text or text-image
    $hero-slide-align: 'center'; // center or left. text-image always implies left aligned text
    $hero-slide-content-text-width: 1*.7; // can be any percentage calculation, eg 1*.8 or 1/4. The image (if applicable) will take the space that is left
    $hero-slide-image-on-small: 'absolute'; // none or absolute; how to show the optional image in a slide on small devices
    $hero-slide-image-on-small-opacity: .4;

    // arrows
    $hero-slide-arrows-enabled: true;
    $hero-slide-arrows-enabled-from: 700px; // 700px
    $hero-slide-arrows-increase-size-from: 1150px;
    $hero-slide-arrows-size-small: 1.5em;
    $hero-slide-arrows-size-large: 2em;
    $hero-slide-arrows-position: 'bottom-centered'; // left-and-right-centered, bottom-centered
    $hero-slide-arrows-bottom-position: 10em; // if bottom-centered
    $hero-slide-arrows-color:  $default-icon-color-light;
    $hero-slide-arrows-hover-color: rgba( $default-icon-color-light, .5);

    // dots
    $hero-slide-dots-border: solid 2px rgba( $default-icon-color-light, .5); 
    $hero-slide-dots-border-active: solid 2px rgba( $default-icon-color-light, .5); 
    $hero-slide-dots-background: transparent; 
    $hero-slide-dots-background-active: $default-background-light; 

    // link to content
    $hero-link-to-content-enabled: true;
    $hero-link-to-content-bottom: 4em;
    $hero-link-to-content-enable-for-medium-portrait-down: false;
    $hero-link-to-content-color: $default-icon-color-light;
    $hero-link-to-content-hover-color: rgba($default-icon-color-light, .5);


    @import '_hero_globals';


    /*--------------------------------------------------------------------------------------------*/
    

    h1, h2, h3 {
        line-height: 1;
    }
    p {
        line-height: 1.5;
    }

    * {
        &:focus {
            outline: none;
        }
    }

    .ac_hero_container {
        @include breakpoint(medium-portrait down){
            height: 50vh;
            min-height: 400px;
        }
    }

    .ac_hero_slide_content_container {
        @include breakpoint(small only){
            transform: translateY($hero-slide-content-correction / 2);
        }
    }

    .ac_hero_slide_content_text {
        color: $default-text-color-light;
    }
    .ac_hero_slide_content_text_title {
        * {
            font-size: 140%;
            color: $default-text-color-light;
            @include breakpoint(medium) {
                font-size: 180%;
            }
        }
    }
    .slick-dots {
        @include breakpoint(small only){
            bottom: 2em;
        }
    }

    

} // end of scope